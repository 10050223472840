import * as React from 'react';
import { SVGProps } from 'react';

const SvgLinkInsertEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.715,16.664c-0.133,0.184-0.281,0.351-0.441,0.512l-2.066,2.066c-1.746,1.738-4.57,1.738-6.32,0l-0.125-0.125c-1.738-1.75-1.738-4.574,0-6.324l2.062-2.066c1.75-1.742,4.574-1.742,6.324,0l0.125,0.125c0.437,0.437,0.778,0.962,1,1.539"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.356,7.25c0.114-0.15,0.24-0.291,0.375-0.422l2.066-2.066c1.746-1.738,4.57-1.738,6.32,0l0.125,0.125c1.738,1.75,1.738,4.574,0,6.324l-2.066,2.062c-1.75,1.742-4.574,1.742-6.324,0l-0.125-0.125c-0.306-0.306-0.567-0.654-0.773-1.035"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgLinkInsertEdit;

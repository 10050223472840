import * as React from 'react';
import { SVGProps } from 'react';

const SvgControlPanel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,8.2V6.7 M12,17.8v-4.9 M17.1,7.6V6.7 M17.1,17.8v-7.6 M6.9,16v1.8 M6.9,6.7v6.7"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.4,3.7h15.2v15.2c0,1.1-0.9,2-2,2H5.4c-1.1,0-2-0.9-2-2V5.7C3.4,4.6,4.3,3.7,5.4,3.7z"
        fill="none"
        strokeLinecap="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={6.9}
        cy={14.7}
        r={1.3}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12}
        cy={11.6}
        r={1.3}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={17.1}
        cy={8.9}
        r={1.3}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgControlPanel;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgSum = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.4,3.4l6,8.6l-6,8.6 M18.6,3.4H5.4 M5.4,20.6h13.2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgSum;

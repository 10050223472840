import * as React from 'react';
import { SVGProps } from 'react';

const SvgDeviceGuide = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.355,2.543c-0.512,0-1.004,0.184-1.391,0.512l-2.703,2.32C5.789,5.777,5.52,6.371,5.52,6.992v12.465 c0,1.098,0.902,2,1.742,2h9.219c1.359,0,2-0.902,2-2V2.543H10.355z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.567,6.543H9.5V2.721"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,16.031v-4.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12}
        cy={9.369}
        r={0.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgDeviceGuide;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgOfferBadgePercent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M9,7c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S7.9,7,9,7z M15,13c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2 S13.9,13,15,13z M7.5,16.5l9-9 M20.1,10.2l1.4,1.8l-1.4,1.9l0.5,2.3l-2,1.1l-0.6,2.2l-2.3,0.1l-1.5,1.8L12,20.4l-2.1,0.9l-1.5-1.8 l-2.3-0.1l-0.6-2.2l-2.1-1.1l0.5-2.3l-1.4-1.9l1.4-1.8L3.4,8l2.1-1.1l0.6-2.2l2.3-0.1l1.5-1.8l2.1,1l2.1-1l1.5,1.8l2.3,0.1l0.6,2.2 l2,1.1L20.1,10.2z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgOfferBadgePercent;

import { Grid, noGuttersMixin } from "../Grid";
import styled, { css } from "styled-components";
import { SectionProps } from "./Section.types";
import {
  sectionPaddingBottomMixin,
  SectionPaddingMixinProps,
  sectionPaddingTopMixin,
} from "../../../styles";
import { DefaultTheme } from "../../../theme";

export type SectionWrapperStyles = Pick<
  SectionProps,
  "backgroundColor" | "paddingBottom" | "paddingTop"
> &
  SectionPaddingMixinProps;

export type SectionGridStylesProps = {
  noGutters?: boolean;
  maxGridWidth?: number;
};

export const JUSTIFY_MAPPING: {
  [key: string]: string;
} = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
};

export const SectionGrid = styled(Grid)<SectionGridStylesProps>`
  position: relative;
  ${({ maxGridWidth }) =>
    maxGridWidth &&
    css`
      max-width: ${maxGridWidth + 40}px;
    `}
  ${({ noGutters }) => noGutters && noGuttersMixin}
`;

export const Section = styled.div<SectionWrapperStyles>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ backgroundColor, theme }) =>
    getBackgroundColor(backgroundColor, theme)};

  ${sectionPaddingBottomMixin};
  ${sectionPaddingTopMixin};
`;

const getBackgroundColor = (Color: string | undefined, theme: DefaultTheme) => {
  switch (Color) {
    case "white":
      return theme.color.monochrome100;

    case "grey":
      return theme.color.background;

    case "black":
      return theme.color.footerBg;

    case "transparent":
      return "transparent";

    default:
      return theme.color.background;
  }
};

import * as React from 'react';
import { SVGProps } from 'react';

const SvgDocumentJpg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.355,2.543c-0.512,0-1.004,0.184-1.391,0.512l-2.703,2.32C5.789,5.777,5.52,6.371,5.52,6.992v12.465 c0,1.098,0.902,2,1.742,2h9.219c1.359,0,2-0.902,2-2V2.543H10.355z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.567,6.543H9.5V2.721"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.926,14.609v-3.051h0.887c0,0,0.891-0.062,0.891,0.891s-0.891,0.887-0.891,0.887h-0.887 M9.016,11.559 v2.551c0.004,0.152-0.059,0.293-0.172,0.391c-0.191,0.164-0.547,0.328-1.098,0.051 M15.555,13.309h0.637v1.238 c0,0-1.734,0.59-1.984-0.938c-0.25-1.547,0.734-1.867,0.93-1.91c0.113-0.027,0.773-0.125,0.988,0.375"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgDocumentJpg;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <line
        vectorEffect="non-scaling-stroke"
        x1={5.5}
        y1={18.5}
        x2={18.5}
        y2={5.5}
        fill="none"
        strokeWidth={1.0838}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        vectorEffect="non-scaling-stroke"
        x1={18.5}
        y1={18.5}
        x2={5.5}
        y2={5.5}
        fill="none"
        strokeWidth={1.0838}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgClose;

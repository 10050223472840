import * as React from 'react';
import { SVGProps } from 'react';

const SvgUnlimited = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.664,10.39c1.163-1.46,2.842-2.623,4.825-2.623c2.748,0,4.89,1.898,4.89,4.233s-2.142,4.233-4.89,4.233 c-5.362,0-5.943-8.467-12.72-8.467c-2.742,0-5.148,1.898-5.148,4.233s2.405,4.233,5.153,4.233c2.539,0,4.149-1.193,5.456-2.678"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgUnlimited;

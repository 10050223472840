import * as React from 'react';
import { SVGProps } from 'react';

const SvgLocationAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.7,12c-0.7,3.9-6.1,8.6-6.1,8.6s-6.1-5.3-6.1-9.4 c0-3.4,2.7-6.1,6.1-6.1 M9.6,5.1c1.5,0,2.9,0.5,4,1.5"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M15,4l-3.3,6.2c-0.2,0.4-0.2,0.8,0,1.2s0.6,0.6,1,0.6h6.7 c0.4,0,0.8-0.2,1-0.6c0.2-0.4,0.2-0.8,0-1.2L17,4c-0.2-0.4-0.6-0.6-1-0.6S15.2,3.7,15,4z M16,5.8v2.8"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.3,10.3c0,0.2-0.1,0.3-0.3,0.3s-0.3-0.1-0.3-0.3S15.8,10,16,10 C16.2,10,16.3,10.1,16.3,10.3z"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,9.6c-0.5-0.7-1.4-1.2-2.3-1.2c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9 c1.4,0,2.5-1,2.8-2.2"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgLocationAlert;

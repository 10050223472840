import * as React from 'react';
import { SVGProps } from 'react';

const SvgAr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.5,6.5v-3h-3"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.5,3.5h-3v3 M3.5,17.5v3h3 M17.5,20.5h3v-3 M10.562,17.652l0.48,0.273l0.477,0.273L12,18.473 M6.508,15.57 l0.523,0.297l0.523,0.301l0.523,0.297 M13.438,17.652l-0.48,0.273l-0.477,0.273L12,18.473 M17.48,15.57l-0.531,0.27l-0.531,0.273 l-0.527,0.273 M6.508,8.433l0.523,0.301l0.523,0.297l0.523,0.301l0.527,0.301 M15.91,7.535l1.047,0.602l0.527,0.297 M12,5.578 l0.477,0.273l0.48,0.273l0.477,0.273 M13.332,10.91l-0.445,0.254l-0.441,0.254L12,11.672 M10.672,10.91l0.441,0.254l0.891,0.508 M17.48,8.433l-0.523,0.301l-0.523,0.297L15.91,9.332l-0.523,0.297 M8.082,7.535L7.035,8.137L6.512,8.433 M12,5.578l-0.48,0.273 l-0.477,0.274l-0.48,0.273 M12,15.953v2.52 M12,11.672v1.59 M17.48,13.965v1.605 M17.484,8.437v1.602 M6.508,13.984v1.586 M6.508,8.437v1.602"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgAr;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgBarcode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.5,6.5v-3h-3"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.5,3.5h-3v3 M3.5,17.5v3h3 M17.5,20.5h3v-3 M14.5,6.5h2v9h-2V6.5z M7.5,6.5h2v9h-2V6.5z M5.5,6.5v11 M12.5,6.5v9 M18.5,6.5v11"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgBarcode;

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { SignInMSAL } from "../SignIn";
import React, { FC, useState } from "react";
import { ThemeProvider } from "styled-components";
import * as S from "./MasterPage.styles";
import { GlobalStyles, BaseCSS, VodafoneFonts } from "../../../styles";
import { themeDark, themeDefault } from "../../../theme";
import { themeModeContext, themeModeDispatchContext } from "./ThemeContext";
interface MasterPageWrapperProps {
  children: {
    element: React.ReactNode;
  };
}

export const MasterPageWrapper: FC<MasterPageWrapperProps> = ({ children }) => {
  const [theme, setTheme] = useState(themeDefault);
  const [themeStatus, setThemeStatus] = useState(false);
  const value = { themeStatus, setThemeStatus };
  React.useEffect(() => {
    const dark_mode_status = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    const storageTheme = localStorage.getItem("theme");
    if (dark_mode_status) {
      setTheme(themeDark);
    } else if (storageTheme === "dark") {
      setTheme(themeDark);
    } else {
      setTheme(themeDefault);
    }
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        event.matches ? setTheme(themeDark) : setTheme(themeDefault);
      });
  }, []);

  const toggleTheme = () => {
    if (theme === themeDefault) {
      setTheme(themeDark);
      localStorage.setItem("theme", "dark");
    } else {
      setTheme(themeDefault);
      localStorage.setItem("theme", "light");
    }
    setThemeStatus(false);
  };
  /*setting de context*/
  return (
    <ThemeProvider theme={theme}>
      <themeModeContext.Provider value={value}>
        <themeModeDispatchContext.Provider
          value={value.themeStatus ? toggleTheme() : false}
        >
          <GlobalStyles />
          <VodafoneFonts />
          <BaseCSS />
          <S.ContentContainer>
            <AuthenticatedTemplate>{children.element}</AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <SignInMSAL />
            </UnauthenticatedTemplate>
          </S.ContentContainer>
        </themeModeDispatchContext.Provider>
      </themeModeContext.Provider>
    </ThemeProvider>
  );
};

import * as React from 'react';
import { SVGProps } from 'react';

const SvgBraking = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.5,20.488h11.992 M14.5,17.5l7-3.5 M17.86,15.82l3.64,4.668 M11.891,9.422l-0.902,1.434 M13.73,9.414 l-1.023,1.582 M18.836,12.25c0.664-0.418,1.648-1.223,1.664-2.09c0.027-1.391-1.711-1.195-2.707-1.082 C15.395,9.355,13,9.551,10.586,9.352l-0.48-0.051C9.465,9.227,8.828,8.867,8.211,8.676c-1.102-0.348-1.109,0.605-1.219,1.336 c-0.176,1.18-1.125,1.609-2.195,1.551c-0.707-0.043-1.844-0.301-2.164,0.559c-0.27,0.715,0.125,1.465,0.281,2.152l0.305,0.914 c0.105,0.309,0.23,0.609,0.375,0.902c0.59,1.41,1.125,2.34,2.652,1.695C9.375,16.312,17.891,13,18.836,12.25z M11.5,3.5L16,7 M16,3.5l2,3 M10.203,9.311l-0.902,1.408"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgBraking;

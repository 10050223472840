import React from "react";
import * as Styled from "./Accrodion.styles";
import { AccordionProps } from "./Accordion.types";
import { AccordionItem } from "../AccordionItem";
import { Section, SectionBackgroundProps } from "../Section";

export const Accordion: React.FC<AccordionProps> = ({
  items,
  paddingTop,
  paddingBottom,
  colWidth = 10,
  backgroundColor = SectionBackgroundProps.GREY,
  foregroundColor,
  allowShadow = true,
  headlineBold,
  styleAs = "h5",
  maxWidth,
  ...props
}) => {
  return (
    <>
      <Section
        backgroundColor={backgroundColor}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom || false}
        maxGridWidth={maxWidth}
        {...props}
      >
        <Styled.AccordionItemsContainer center="lg">
          {items.map((item) => (
            <Styled.AccordionItemCol xs={12} lg={colWidth} key={item?.id}>
              <AccordionItem
                header={(item?.header as string) ?? undefined}
                headlineIcon={item.headlineIcon}
                hideDivider={item.hideDivider}
                open={item?.open || false}
                id={item?.id}
                allowShadow={allowShadow}
                backgroundColor={foregroundColor}
                headlineBold={headlineBold}
                styleAs={styleAs}
                unmountOnCollapse={item?.unmountOnCollapse || false}
              >
                {item.children}
              </AccordionItem>
            </Styled.AccordionItemCol>
          ))}
        </Styled.AccordionItemsContainer>
      </Section>
    </>
  );
};

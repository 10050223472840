import * as React from 'react';
import { SVGProps } from 'react';

const SvgCalendarMonth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.5,19.531c0,1.102-0.895,2-2,2h-13c-1.105,0-2-0.898-2-2v-13c0-1.105,0.895-2,2-2h15V19.531z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8,2.531v3 M16,2.531v3 M20.5,7.566h-17 M6.5,10.551h1.578c0.277,0,0.5,0.223,0.5,0.5v1.578 c0,0.277-0.223,0.5-0.5,0.5H6.5c-0.277,0-0.5-0.223-0.5-0.5v-1.578C6,10.773,6.223,10.551,6.5,10.551z M11.18,10.551h1.578 c0.277,0,0.5,0.223,0.5,0.5v1.578c0,0.277-0.223,0.5-0.5,0.5H11.18c-0.277,0-0.5-0.223-0.5-0.5v-1.578 C10.68,10.773,10.902,10.551,11.18,10.551z M15.922,10.547H17.5c0.277,0,0.5,0.223,0.5,0.5v1.578c0,0.273-0.223,0.5-0.5,0.5h-1.578 c-0.277,0-0.5-0.227-0.5-0.5v-1.578C15.422,10.77,15.645,10.547,15.922,10.547z M6.5,15.973h1.578c0.277,0,0.5,0.223,0.5,0.5v1.578 c0,0.277-0.223,0.5-0.5,0.5H6.5c-0.277,0-0.5-0.223-0.5-0.5v-1.578C6,16.195,6.223,15.973,6.5,15.973z M11.18,15.973h1.578 c0.277,0,0.5,0.223,0.5,0.5v1.578c0,0.277-0.223,0.5-0.5,0.5H11.18c-0.277,0-0.5-0.223-0.5-0.5v-1.578 C10.68,16.195,10.902,15.973,11.18,15.973z M15.922,15.965H17.5c0.277,0,0.5,0.227,0.5,0.5v1.582c0,0.273-0.223,0.5-0.5,0.5h-1.578 c-0.277,0-0.5-0.227-0.5-0.5v-1.582C15.422,16.191,15.645,15.965,15.922,15.965z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgCalendarMonth;

import React, { FC } from "react";
import * as S from "./CircularBackButton.styles";
import { Icon } from "../Icon";

interface CircularBackButtonProps {
  onClick: () => void;
}

export const CircularBackButton: FC<CircularBackButtonProps> = ({
  onClick,
}) => {
  return (
    <S.CircularBackButton onClick={onClick}>
      <Icon as={"ArrowLeft"} />
    </S.CircularBackButton>
  );
};

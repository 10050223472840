import * as React from 'react';
import { SVGProps } from 'react';

const SvgCallIncoming = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.035,5.984v5.035h5.039"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.492,17.215c0.012,0.031,0.016,0.066,0.016,0.105c-0.004,0.328-0.055,0.652-0.152,0.969 c-0.375,1.215-1.387,2.523-2.582,2.875c-3.016,0.898-6.191-0.023-9-1.434c-2.32-1.16-4.211-3.035-5.387-5.348 c-1.414-2.797-2.34-5.957-1.438-8.957C3.305,4.238,4.625,3.23,5.844,2.859l0.98-0.258c0.164-0.043,0.328,0.047,0.383,0.203 L7.98,5.07C8.375,6.172,8.77,7.273,9.164,8.375l0.027,0.086c0.055,0.152,0.008,0.324-0.125,0.426 c-0.383,0.289-0.812,0.516-1.27,0.664C7.254,9.719,6.691,9.828,6.125,9.875c-0.109,0.012-0.207,0.07-0.27,0.164 c-0.059,0.09-0.074,0.203-0.043,0.309c0.594,1.875,1.625,3.582,3.012,4.977c1.402,1.387,3.117,2.418,5,3.004 c0.105,0.035,0.219,0.02,0.309-0.043c0.09-0.059,0.152-0.156,0.164-0.266c0.047-0.566,0.156-1.129,0.328-1.672 c0.152-0.457,0.379-0.883,0.672-1.262c0.098-0.133,0.273-0.184,0.426-0.125l0.086,0.027l3.316,1.18 c0.789,0.277,1.461,0.559,2.25,0.84C21.422,17.023,21.469,17.141,21.492,17.215z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.035,11.02l6.492-6.488"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgCallIncoming;

import React, { FC } from "react";
import * as S from "./BurgerMenu.styles";
import { BurgerMenuProps } from "./BurgerMenu.types";

export const BurgerMenu: FC<BurgerMenuProps> = ({ isOpen, light, onClick }) => {
  return (
    <S.BurgerMenu
      role="button"
      isOpen={isOpen}
      onClick={onClick}
      aria-label={isOpen ? "Menü schließen" : "Menü öffnen"}
    >
      <S.BurgerMenuIcon isOpen={isOpen} light={light} />
    </S.BurgerMenu>
  );
};

import * as React from 'react';
import { SVGProps } from 'react';

const SvgTwitterBoxed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Icon"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g fill="#ffffff">
      <path d="M20.6,6.7c-0.6,0.3-1.3,0.5-2,0.6c0.7-0.5,1.3-1.2,1.6-2c-0.7,0.4-1.5,0.7-2.2,0.9c-1.3-1.4-3.6-1.5-5-0.1 c-0.9,0.9-1.3,2.1-1,3.3C9.1,9.2,6.4,7.8,4.6,5.6c-0.9,1.6-0.5,3.7,1.1,4.7c-0.6,0-1.1-0.1-1.6-0.4l0,0c0,1.7,1.2,3.1,2.8,3.5 c-0.5,0.1-1.1,0.2-1.6,0.1C5.8,14.9,7.1,16,8.6,16c-1.5,1.1-3.4,1.6-5.2,1.4c4.6,3,10.8,1.7,13.8-2.9c1.1-1.6,1.6-3.5,1.6-5.5 c0-0.1,0-0.3,0-0.4C19.6,8,20.1,7.4,20.6,6.7z" />
    </g>
  </svg>
);

export default SvgTwitterBoxed;

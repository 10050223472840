import * as React from 'react';
import { SVGProps } from 'react';

const SvgWaterHot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.4,3.4h13.3v4.8 M12.9,8.2v-1 H3.4 M12.9,8.2h3.8"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d=" M12.9,10.1v5.7"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d=" M14.8,10.1v6.7"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d=" M16.7,10.1v5.7"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11,12 c-1.7,0-2.9,1.3-2.9,3s1,3.1,3.2,3.1c0.2,0,0.3,0,0.5,0l0,0l0,0c0.6,1.4,2,2.5,3.5,2.5c1.7,0,3.1-1.4,3.4-3.2l0,0 c1.1-0.3,1.9-1.4,1.9-2.7s-0.8-2.3-1.9-2.6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgWaterHot;

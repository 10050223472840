import styled from "styled-components";
import { spacing } from "../../../theme";
import { Col, deviceUp, Row } from "../Grid";

export const AccordionItemsContainer = styled(Row)`
  padding-top: ${spacing("xl")};
  padding-bottom: ${spacing("xxxl")};

  @media ${deviceUp.m} {
    padding-top: ${spacing("xxl")};
  }
`;

export const AccordionItemCol = styled(Col)`
  margin-bottom: ${spacing("m")};

  @media ${deviceUp.m} {
    margin-bottom: ${spacing("l")};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.806,4.321l-7,12.961c-0.661,1.212-0.215,2.731,0.997,3.392 C4.17,20.875,4.582,20.98,5,20.98h14c1.381,0,2.5-1.12,2.499-2.501c0-0.418-0.105-0.83-0.305-1.197l-7-12.961 c-0.662-1.212-2.181-1.657-3.393-0.995C10.381,3.556,10.036,3.901,9.806,4.321z M12,7.983v7.062"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12}
        cy={17.483}
        r={0.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgWarning;

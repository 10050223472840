import * as React from 'react';
import { SVGProps } from 'react';

const SvgVCar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.445,16.031c0,0.157-0.015,0.314-0.045,0.469c-0.259,1.356-1.568,2.246-2.925,1.987 c-1.007-0.192-1.794-0.98-1.987-1.987c-0.257-1.357,0.635-2.665,1.991-2.921c0.153-0.029,0.309-0.044,0.465-0.044 C8.324,13.535,9.443,14.652,9.445,16.031z M19.476,16.031c0,0.157-0.015,0.314-0.045,0.469c-0.261,1.356-1.571,2.244-2.927,1.983 c-1.004-0.193-1.79-0.979-1.983-1.983c-0.03-0.154-0.045-0.311-0.045-0.469c0-1.381,1.119-2.5,2.5-2.5S19.476,14.651,19.476,16.031 z M21.459,15.212c-0.001,0.711-0.577,1.287-1.288,1.288h-0.74c0.03-0.154,0.045-0.311,0.045-0.469c0-1.381-1.119-2.5-2.5-2.5 s-2.5,1.119-2.5,2.5c0,0.157,0.015,0.314,0.045,0.469H9.396c0.03-0.154,0.045-0.311,0.045-0.469c0-1.381-1.119-2.5-2.5-2.5 s-2.5,1.119-2.5,2.5c0,0.156,0.015,0.312,0.044,0.465c-1.139-0.073-2.026-1.017-2.026-2.159c-0.001-2.119,1.716-3.837,3.835-3.837 c0.001,0,0.002,0,0.003,0h9.019C19.445,10.5,21.459,12.609,21.459,15.212L21.459,15.212z M3.279,11.969 C4.146,10.5,5.969,6.5,10.23,6.5c3.125,0,4.75,1.216,7.202,4.216"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgVCar;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgCarKey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.743,13.772l-6.29,6.298l0.008,1.41l2.125,0.02l0.348-1.074l1.426-0.699l0.352-1.059l3.458-3.47 M19.684,8.59l-4.875,4.875c-0.879,0.871-2.297,0.871-3.172,0l-1.062-1.062c-0.871-0.875-0.871-2.293,0-3.172l3.543-3.543 c0.879-0.875,2.297-0.875,3.176,0L19.684,8.59z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.559,3.445l3.074,3.074c0.977,0.977,0.977,2.559,0,3.535l-5.324,5.328c-1.367,1.367-3.586,1.367-4.949,0 l-1.664-1.664c-1.367-1.363-1.367-3.582,0-4.949l5.328-5.324C15,2.469,16.582,2.469,17.559,3.445z M12.346,7.459l4.232,4.236"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgCarKey;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgSolar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.9,10.4h7.3L17,17.4c-0.3,0.8-1.1,1.4-1.9,1.4H6.5c-0.8,0-1.2-0.6-1-1.4l1.4-4.4 M6.4,14.6h11.2 M13.4,10.6 l-2.6,7.9 M9.1,5.7c-1-1-2.6-1-3.6,0s-1,2.6,0,3.6s2.6,1,3.6,0S10.1,6.7,9.1,5.7z M9.3,5.5l0.8-0.8 M4.6,10.3l0.8-0.8 M9.3,9.5 l0.8,0.8 M4.6,4.7l0.8,0.8 M11.3,7.5h-1.2 M3.4,7.5h1.2 M7.4,10.3v1.2 M7.4,3.6v1.2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.5,17.4c-0.9,3,1.5,3.1,2.3,3.1H15c1.6,0,3.1-1,3.7-2.6l1.9-6.1l-1.4-1.3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgSolar;

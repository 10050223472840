import * as React from 'react';
import { SVGProps } from 'react';

const SvgNumber6 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,12.027c-0.016,5.242-4.258,9.484-9.5,9.5c-5.242-0.016-9.484-4.258-9.5-9.5 c0.016-5.238,4.258-9.484,9.5-9.5h9.5V12.027z"
        fill="none"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.234,7.57c-0.824,0-3.281,0.262-4.207,3.375c-0.246,0.844-0.395,1.715-0.445,2.594 c-0.129,1.652,0.793,3.086,2.355,3.086c1.566,0,2.59-1.391,2.484-2.988c-0.102-1.594-1.258-2.512-2.391-2.512 c-0.973,0-2.375,0.75-2.41,1.934"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgNumber6;

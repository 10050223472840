import * as React from 'react';
import { SVGProps } from 'react';

const SvgBill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.355,2.508c-0.512,0-1.004,0.18-1.391,0.512L6.262,5.34C5.789,5.746,5.52,6.336,5.52,6.957v12.465 c0,1.098,0.902,2,1.742,2h9.219c1.359,0,2-0.902,2-2V2.508H10.355z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.577,6.477H9.5V2.693"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.973,10.512h-4 M15.973,13.521h-4 M15.973,16.531h-4 M9.5,10.512H8.477 M9.5,13.521H8.477 M9.5,16.531 H8.477"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgBill;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgCo2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.6,6.2c-1.7,0-3.2,1-4,2.4C9.2,8.4,8.7,8.2,8.2,8.2 c-1.5,0-2.8,1.3-2.8,2.8c0,0.2,0,0.4,0.1,0.6c-1.2,0.5-2.1,1.6-2.1,3c0,1.8,1.4,3.2,3.2,3.2h10c2.2,0,4-1.8,4-4 c0-1.7-1-3.1-2.5-3.7C17.8,7.9,15.9,6.2,13.6,6.2L13.6,6.2z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10,14.6c-0.4,0.5-0.8,0.5-1.1,0.5c-1,0-1.5-0.4-1.5-2.1 c0-1,0.1-1.3,0.4-1.6c0.9-0.9,1.9-0.3,2.1,0.3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.9,11.7c0.2-0.6,1.1-0.5,1.3-0.5c0.3,0,0.5,0.1,0.7,0.3 c0.1,0.1,0.3,0.3,0.3,0.5c0,0.1,0,0.3,0,0.4c0,0.7-0.8,1.2-0.9,1.3l0,0c-0.5,0.4-0.9,0.8-0.9,1.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.3 h2.2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.4,15.4c-0.7,0-1.2-0.2-1.6-1.1c-0.4-0.8-0.2-1.7,0-2.4 c0.2-0.7,1-1.1,1.6-1.1c0.9,0,1.5,0.7,1.6,1.1c0.1,0.4,0.4,1.3,0,2.4C13.7,15,13.2,15.4,12.4,15.4z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgCo2;

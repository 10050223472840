import * as React from 'react';
import { SVGProps } from 'react';

const SvgCallEnd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.551,16.341c-0.025-0.011-0.048-0.03-0.07-0.052c-0.188-0.193-0.347-0.411-0.474-0.651 c-0.488-0.924-0.66-2.272-0.17-3.17c1.23-2.274,3.611-3.583,6.062-4.396c2.022-0.674,4.21-0.683,6.236-0.023 c2.446,0.803,4.82,2.101,6.039,4.369c0.483,0.896,0.302,2.249-0.191,3.173l-0.42,0.719c-0.07,0.12-0.218,0.163-0.34,0.104 l-1.766-0.867c-0.869-0.411-1.738-0.822-2.608-1.232l-0.066-0.034c-0.12-0.057-0.193-0.184-0.175-0.32 c0.054-0.39,0.172-0.772,0.352-1.123c0.218-0.413,0.481-0.803,0.783-1.16c0.057-0.07,0.079-0.161,0.061-0.252 c-0.018-0.086-0.075-0.161-0.154-0.204c-1.434-0.744-3.025-1.137-4.641-1.142c-1.62,0.009-3.216,0.406-4.65,1.16 c-0.082,0.041-0.138,0.116-0.154,0.204c-0.018,0.086,0.002,0.179,0.059,0.25c0.302,0.356,0.565,0.747,0.781,1.162 c0.177,0.354,0.293,0.733,0.343,1.123c0.02,0.134-0.052,0.266-0.175,0.32l-0.066,0.034l-2.612,1.241 c-0.62,0.297-1.173,0.524-1.795,0.819C3.703,16.412,3.608,16.371,3.551,16.341z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgCallEnd;

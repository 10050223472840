import styled from "styled-components";

export const LocationPin = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.color.primary100};
  border-radius: 12px 12px 0 12px;
  position: relative;
  transform: translateY(-50%) rotate(45deg);

  &::after {
    content: "";
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.color.primary200};
    position: absolute;
    left: 7px;
    top: 7px;
    border-radius: 50%;
  }
`;

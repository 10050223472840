import * as React from 'react';
import { SVGProps } from 'react';

const SvgGlassfiber = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,16.5h-6v-9h6 M7,7c2.44,1.627,4.875,2.5,7,2.5c1.125,0,1.5,0,1.5,0 M6,16.875 c4.25-2.375,6.5-2.375,8.75-2.375h0.75 M4,13.5c1.75-1.125,11-1,11-1h0.5 M3,9c2.125,1.75,9.375,2.499,11,2.499h1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={7}
        cy={7}
        r={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={3}
        cy={9}
        r={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={4}
        cy={13.5}
        r={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={6}
        cy={16.875}
        r={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgGlassfiber;

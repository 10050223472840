import * as React from 'react';
import { SVGProps } from 'react';

const SvgUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.5,15.7c-0.4-2.6-2-4.2-4-4.2c-1.4,0-2.7,0.8-3.4,2.3l0,0 M16.5,6.5H19V9c0,1.4-1.1,2.5-2.5,2.5l0,0 c-1.4,0-2.5-1.1-2.5-2.5l0,0C14,7.6,15.1,6.5,16.5,6.5z M3.5,17.5c0.6-3.7,2.8-6,5.5-6s4.9,2.3,5.5,6 M9,4.5h3.5V8 c0,1.9-1.6,3.5-3.5,3.5l0,0c-1.9,0-3.5-1.6-3.5-3.5l0,0C5.5,6.1,7.1,4.5,9,4.5z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgUsers;

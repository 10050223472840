import * as React from 'react';
import { SVGProps } from 'react';

const SvgDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M14,18V9 M10,18V9 M4.5,5.5h15 M15,5.5v-1c0-1.1-0.9-2-2-2h-2c-1.1,0-2,0.9-2,2v1 M18.5,5.5v14 c0,1.1-0.9,2-2,2h-9c-1.1,0-2-0.9-2-2v-14"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgDelete;

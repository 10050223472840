import * as React from 'react';
import { SVGProps } from 'react';

const SvgMyTrips = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <circle
        vectorEffect="non-scaling-stroke"
        cx={8.7}
        cy={12.2}
        r={2.5}
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M14,12.2c0,3.5-5.3,8.1-5.3,8.1s-5.3-4.6-5.3-8.1C3.4,9.3,5.8,7,8.7,7"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.7,7L8.7,7c2.9,0,5.3,2.4,5.3,5.3c0,0,0,0,0,0l0,0"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.8,20.4h5.7c7.5,0,6.8-4.2,3.3-5.3c-3-0.9-2-3.7-0.2-4.1"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={17.7}
        cy={6.5}
        r={1.3}
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.6,6.5c0,1.9-2.9,4.4-2.9,4.4s-2.9-2.5-2.9-4.4c0-1.6,1.3-2.9,2.9-2.9l0,0"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.7,3.6L17.7,3.6c1.6,0,2.9,1.3,2.9,2.9c0,0,0,0,0,0l0,0"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgMyTrips;

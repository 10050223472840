import * as React from 'react';
import { SVGProps } from 'react';

const SvgCalendar31 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.5,19.531c0,1.102-0.895,2-2,2h-13c-1.105,0-2-0.898-2-2v-13c0-1.105,0.895-2,2-2h15V19.531z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8,2.531v3 M16,2.531v3 M20.5,7.566h-17 M8.809,13.938c0.168-0.031,0.34-0.051,0.516-0.047 c1.301,0,2.355,0.902,2.355,2.016c0,1.117-1.055,2.156-2.355,2.156c-0.797,0.02-1.543-0.395-1.949-1.078 M7.371,11.328h4.039 l-2.609,2.609 M14.102,12.406l2.152-1.344v7"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgCalendar31;

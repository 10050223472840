import * as React from 'react';
import { SVGProps } from 'react';

const SvgUpgrade = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.82,7.711l2.094-2.086c0.023-0.023,0.055-0.035,0.09-0.035c0.031,0,0.066,0.012,0.09,0.035l2.086,2.086 M12,12.531V5.59"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.5,19.531h3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.5,2.531h9v17c0,1.105-0.895,2-2,2h-7c-1.105,0-2-0.895-2-2v-15C6.5,3.426,7.395,2.531,8.5,2.531z"
        fill="none"
      />
    </g>
  </svg>
);

export default SvgUpgrade;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgConnectedDevices = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.586,15.139h-6.135 M18.516,17.564h0.97 M5.932,17.556h0.969 M14.386,17.556h-3.935 M12.506,15.139 l0.004,2.417"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.079,10.531h3.345V18c0,0.828-0.672,1.5-1.5,1.5h-1.845c-0.828,0-1.5-0.672-1.5-1.5v-5.969 C16.579,11.203,17.25,10.531,18.079,10.531z M4.036,7.5h6.415v10.538c0,0.828-0.672,1.5-1.5,1.5H4.036c-0.828,0-1.5-0.672-1.5-1.5 V9C2.536,8.172,3.208,7.5,4.036,7.5z M20.519,10.531V3.506H5.625C4.451,3.506,3.5,4.458,3.5,5.631v1.967"
        fill="none"
        strokeLinecap="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12.506}
        cy={13.516}
        r={0.019}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgConnectedDevices;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgBoye = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.7,17l0.3,3 M16.2,6.7L7,7.7L7.6,13c0.5,4.2,3,4.2,5.1,3.9c2-0.2,4.4-0.5,4.1-4.6L16.2,6.7z M16.2,6.7 l-1.6-1.3L8.3,6.1L7,7.7 M11.6,7.2l-0.2-1.4 M14.6,5.4l1.9-0.2L16.3,4L6.2,5.1l0.1,1.2l1.9-0.2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.4,10.2c3,1.5,5.1,1.6,8.5,0.9c2.7-0.5,4.2-1.6,8.7,0.6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgBoye;

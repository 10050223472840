import * as React from 'react';
import { SVGProps } from 'react';

const SvgConnectedCar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.484,16.465c-1.141-0.07-2.027-1.016-2.031-2.16c0-0.848,0.277-1.672,0.797-2.344 c0.262-0.328,0.496-0.676,0.703-1.039c0.934-1.688,2.562-4.453,6.27-4.453c3.109,0,4.727,1.199,7.152,4.145 c0.035,0.047,0.09,0.078,0.145,0.086c2.012,0.391,3.938,2.352,3.938,4.48c0,0.711-0.574,1.289-1.285,1.289H19.43 M14.52,16.469 H9.395 M10.086,12.086c0.781-0.781,2.047-0.781,2.828,0 M8.672,10.672c1.562-1.562,4.094-1.562,5.656,0"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={6.944}
        cy={16}
        r={2.5}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={16.977}
        cy={16}
        r={2.5}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={11.5}
        cy={13.5}
        r={0.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgConnectedCar;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgYoutubeBoxedSw = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g fill="#0d0d0d">
      <path d="M21.8,7.8c-0.1-1.2-1-2.1-2.2-2.3c-5-0.7-10.1-0.7-15.1,0C3.3,5.7,2.4,6.7,2.2,7.8c-0.3,2.8-0.3,5.6,0,8.4 c0.1,1.2,1,2.1,2.2,2.3c2.5,0.3,5,0.5,7.6,0.5c2.5,0,5-0.2,7.5-0.5c1.2-0.1,2.1-1.1,2.2-2.3c0.2-1.4,0.2-2.8,0.2-4.2 C22,10.6,21.9,9.2,21.8,7.8L21.8,7.8z M14.9,12.4l-4.1,2.9c-0.2,0.1-0.4,0.1-0.5-0.1c0-0.1-0.1-0.1-0.1-0.2V9 c0-0.2,0.1-0.4,0.3-0.4c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0.1l4.1,2.9c0.2,0.2,0.3,0.5,0.1,0.7l0,0C15,12.3,15,12.4,14.9,12.4L14.9,12.4 z" />
    </g>
  </svg>
);

export default SvgYoutubeBoxedSw;

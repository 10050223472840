import * as React from 'react';
import { SVGProps } from 'react';

const SvgRobot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.7,7.5h10.1v7.9c0,1.9-1.6,3.5-3.5,3.5H8.7c-1.9,0-3.5-1.6-3.5-3.5v-4.4C5.2,9,6.8,7.5,8.7,7.5z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.1,9.7h5.9c1.1,0,2,0.9,2,2v3.2c0,1.1-0.9,2-2,2H9.1c-1.1,0-2-0.9-2-2v-3.2C7,10.6,7.9,9.7,9.1,9.7z M10,12 v0.9 M14,12v0.9"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.8,14.7c-0.2,0.3-0.5,0.5-0.8,0.5c-0.4,0-0.7-0.2-0.8-0.5 M18.8,11.5c1,0,1.8,0.8,1.8,1.8s-0.8,1.8-1.8,1.8  M5.2,11.5c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8 M12,7.5c0,0,0-2.3,0-2.3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgRobot;

import React, { FC, useEffect, useState } from "react";
import { ChartProps, DateRangeProps, zoomLevelProps } from "./Chart.types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import * as S from "./Chart.styles";
import { SmallButton } from "../SmallButton";
import { DateRangePicker } from "../DateRangePicker";
import { dataMapping } from "./ChartFunctions";
import { Badge } from "../Badge";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const oneMonthAgo = new Date();
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
oneMonthAgo.setHours(0, 0, 0, 0);

export const Chart: FC<ChartProps> = ({
  chartData,
  chartTitle,
  noZoom,
  format,
  today,
  sendData
}) => {
  const [zoomLevel, setZoomLevel] = useState<zoomLevelProps>("all");
  const [dateRange, setDateRange] = useState<DateRangeProps>({
    start: new Date(oneMonthAgo),
    end: new Date(new Date().setHours(23, 59, 59, 0)),
  });
  const [data, setData] = useState<{ labels: string[]; datasets: [] }>({
    labels: [],
    datasets: [],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          autoSkipPadding: 20,
        },
      },
      y: {
        ticks: {
          callback: (value: number, index: number, values: number[]) =>
            `${value} ${chartData.unit}`,
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: chartTitle,
      },
    },
  };

  useEffect(() => {
    // Call functions to filter Data
    const filteredData = dataMapping(
      chartData.data,
      chartData.measurement,
      zoomLevel,
      dateRange,
      chartData.limitLow,
      chartData.limitHigh,
      format
    );

    setData({
      labels: filteredData.labels,
      datasets: filteredData.datasets,
    });

    // only used when we need to bubble data back to parent
    if (sendData)
      sendData(dateRange)

  }, [zoomLevel, dateRange, chartData]);

  /**
   * Change range settings
   */
  const changeDateRange = (date: Date, type: "start" | "end") => {
    setDateRange({
      start: type === "start" ? date : dateRange.start,
      end:
        type === "end" ? new Date(date.setHours(23, 59, 59, 0)) : dateRange.end,
    });
  };

  const setToday = () => {
    setDateRange({
      start: new Date(new Date().setHours(0, 0, 0, 0)),
      end: new Date(new Date().setHours(23, 59, 59, 0)),
    });
  };

  return (
    <>
      <S.ChartWrapper>
        <Badge color={"primary100"} text={"Live"} live />
        <Line data={data} options={options} />
      </S.ChartWrapper>
      {!noZoom && (
        <S.ChartControls>
          <S.TabGroup>
            <SmallButton
              label={"All Data"}
              variant={zoomLevel === "all" ? "primary" : "tertiary-opt1"}
              onClick={() => setZoomLevel("all")}
            />
            <SmallButton
              label={"Hourly"}
              variant={zoomLevel === "hourly" ? "primary" : "tertiary-opt1"}
              onClick={() => setZoomLevel("hourly")}
            />
            <SmallButton
              label={"Daily"}
              variant={zoomLevel === "daily" ? "primary" : "tertiary-opt1"}
              onClick={() => setZoomLevel("daily")}
            />
            <SmallButton
              label={"Weekly"}
              variant={zoomLevel === "weekly" ? "primary" : "tertiary-opt1"}
              onClick={() => setZoomLevel("weekly")}
            />
          </S.TabGroup>
        </S.ChartControls>
      )}
      <S.DateWrapper>
        <DateRangePicker
          customFn={(date: Date, type: "start" | "end") =>
            changeDateRange(date, type)
          }
          startDate={dateRange.start}
          endDate={dateRange.end}
        />
        {today && (
          <S.Button
            label={"Today"}
            variant={"secondary"}
            onClick={() => setToday()}
          />
        )}
      </S.DateWrapper>
    </>
  );
};

import * as React from 'react';
import { SVGProps } from 'react';

const SvgDocumentDoc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.355,2.543c-0.512,0-1.004,0.184-1.391,0.512l-2.703,2.32C5.789,5.777,5.52,6.371,5.52,6.992v12.465 c0,1.098,0.902,2,1.742,2h9.219c1.359,0,2-0.902,2-2V2.543H10.355z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.567,6.543H9.5V2.721"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.441,11.625v2.816h0.625c0,0,1.309,0.184,1.309-1.441c0-1.5-1.312-1.375-1.312-1.375H7.441z M13.062,13.035 c0-0.828-0.484-1.5-1.082-1.5c-0.602,0-1.086,0.672-1.086,1.5c0,0.832,0.484,1.504,1.086,1.504 C12.578,14.539,13.062,13.867,13.062,13.035z M16.383,14.191c-0.176,0.215-0.434,0.34-0.711,0.348c-0.613,0-1.113-0.676-1.113-1.5 c0-0.828,0.5-1.5,1.113-1.5c0.332,0.012,0.633,0.184,0.812,0.461"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgDocumentDoc;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgBee = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.4,8.6c2.1-0.6,6.6-0.8,7.7,1c0.8,1.6-0.2,4.3-2.5,4.9c0,0-0.7-2.1-2.6-3.5c-0.8-0.6-1.8-1-3.1-1.1 C11.4,9.3,11.6,8,11.1,7c-0.7-1.2-2.2-1.6-3.4-0.9C7.4,6.3,7.2,6.5,7,6.7C6.4,7.5,6.2,8.6,6.8,9.6c0.3,0.5,0.7,0.8,1.2,1 c0.4,0.2,1.2,0.2,1.4,0.2c-0.6,1-0.7,2.1-0.5,3.2c0.3,1.9,1.3,3.6,1.7,4c-1.9,1.7-4.4,1.3-5.5-0.2C4,16,6.4,12.1,8,10.6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7,6.7c0,0-1.5-1.3-2.4-0.8"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M9,3.4C8.1,3.9,8.5,5.8,8.5,5.8"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.1,11c-1.5,0-4.3,1.8-5.2,2.9"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M16,13.2c-1.7,0-5.1,1.4-6.2,3.6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.5,18c1.8,2.1,4.1,1.9,4.4,1.8c0.3-0.2,1.2,0.8,1.2,0.8"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.1,20.6c0,0-0.4-1.3-0.1-1.5s1.5-2.2,0.6-4.7"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.9,15.9c-1.8,0-4.3,1.4-5.2,3.1"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgBee;

import {
  DeviceProps,
  UrlParamsProps,
  ProjectProps,
  Measurement,
  DataProps,
} from "./Data.types";
import { supportedProjects } from "../config/config";
import { SanitizerDeviceDetailProps } from "../components/ProjectComponents";

/**
 * This file is intended to be an assortment of helpful functions that can be used on component level
 */

/**
 * Returns the index of a specific device in an array.
 * This is used to associate newly received data to the correct device.
 * @param arr - Array of all devices
 * @param val - IMEI associated with the newly available data
 */
export function getIndexByObjectImei(
  arr: DeviceProps[] | SanitizerDeviceDetailProps[],
  val: string
) {
  return arr
    .map(function (e) {
      return e.imei;
    })
    .indexOf(val);
}

/**
 *
 * @param data - The data to be scanned for maximum values
 * @param measurement - specifies for what measurement you would like to receive the max values.
 * @param limitHigh - specifies the highest possible sensor value.
 * Anything HIGHER will be assumed to be corrupt data.
 * @param limitLow - specifies the lowest possible sensor value.
 * Anything LOWER will be assumed to be corrupt data.
 */
export function getMaxValues(
  data: DeviceProps[],
  measurement: Measurement,
  limitLow: number,
  limitHigh: number
) {
  const highestVal: number = data.reduce((acc, cur) => {
    // No data
    if (!cur.data) {
      return acc;
    }

    const current = cur.data[measurement];

    // Corrupt data
    if (current > limitHigh || current < limitLow) {
      return acc;
    }

    return current
      ? current <= limitHigh
        ? current > acc
          ? current
          : acc
        : acc
      : acc;
  }, limitLow);

  let lowestVal: number = data.reduce((acc, cur) => {
    // No data
    if (!cur.data) {
      return acc;
    }
    const current = cur.data[measurement];

    // Corrupt data
    if ((current > limitHigh || current < limitLow) && acc !== limitHigh) {
      return acc;
    }
    return current
      ? current > limitLow
        ? current <= acc
          ? current
          : acc
        : acc
      : acc;
  }, limitHigh);

  // Fix lowest value if all data is invalid
  lowestVal = lowestVal <= limitHigh ? lowestVal : limitLow;

  const highestValTimestamp = data.find((f) =>
    f.data && f.data[measurement] ? f.data[measurement] === highestVal : false
  )?.timestamp;

  const lowestValTimestamp = data.find((f) =>
    f.data && f.data[measurement] ? f.data[measurement] === lowestVal : false
  )?.timestamp;

  return {
    highestVal: {
      value: highestVal,
      timestamp: highestValTimestamp,
    },
    lowestVal: {
      value: lowestVal,
      timestamp: lowestValTimestamp,
    },
  };
}

/**
 * Returns a list of all device IMEIs of a project overview.
 * @param data
 */
export function getOverviewImeiList(data: DeviceProps[]) {
  const imeiList: string[] = [];
  data.forEach((device) => {
    imeiList.push(device.imei);
  });

  return imeiList;
}

/**
 * Returns Icon name dependent  on current battery percentage
 * @param value
 */
export function getBatteryIndicator(value: number) {
  if (value >= 85) {
    return "BatteryFull";
  } else if (value >= 70) {
    return "BatteryHigh";
  } else if (value >= 45) {
    return "BatteryMid";
  } else if (value >= 25) {
    return "BatteryLow";
  } else if (value >= 12.5) {
    return "BatteryReallyLow";
  }
  return "BatteryEmpty";
}

/**
 * Calculates battery percentage based on charge value read by device.
 * It calculates the value based on a datasheet.
 * The magic numbers are values measured in battery tests.
 * @param value
 */
export function calculateBatteryPercentage(value: number) {
  value = value / 1000;
  const vol =
    Math.round((-0.5104 * value * value + 4.689 * value - 6.311) * 100) / 100;
  if (vol <= 3.3) {
    return 0;
  } else if (vol >= 4.2) {
    return 100;
  } else {
    const result = Math.round((1.18303 * vol - 3.90836) * 100);
    return result > 100 ? 100 : result;
  }
}

/**
 * Returns all URL parameters of current location
 */
export function getUrlParams() {
  const params: UrlParamsProps = {};
  new URLSearchParams(document.location.search.substring(1)).forEach(
    (value, key) => {
      params[key] = value;
    }
  );
  return params;
}

/**
 * Helper function to parse stringified JSON objects
 * @param data
 */
export function maybeParseJson(data: string | ArrayBuffer) {
  if (typeof data === "string" && data.startsWith("{")) {
    return JSON.parse(data);
  }
  return data;
}

export function parseUpdateData(update: any) {
  return {
    imei: update.imei,
    timestamp: update.timestamp,
    data: update.data,
    location: update.geolocation,
    network: update.network,
  };
}

export function filterSupportedProjects(projects: ProjectProps[]) {
  const filtered: ProjectProps[] = [];

  projects.filter(function (project: ProjectProps) {
    return supportedProjects.filter(function (supportedProject) {
      if (project.id === supportedProject) {
        filtered.push(project);
      }
    });
  });
  return filtered;
}

/**
 * Calculates the average value of array elements
 * @param arr
 */
export function average(arr: number[]) {
  return arr.reduce((p, c) => p + c, 0) / arr.length;
}

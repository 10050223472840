import * as React from 'react';
import { SVGProps } from 'react';

const SvgSoundActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.841,8c2.211,2.211,2.211,5.796,0,8.007l0,0"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.514,9.583c1.343,1.343,1.343,3.52,0.001,4.863c0,0,0,0-0.001,0.001l0,0 M15.483,21.455L9.2,16.101 c-0.561-0.436-0.993-0.565-1.597-0.568h-3.21c-1.036,0-1.875-0.839-1.875-1.875v-3.25c0-1.036,0.839-1.875,1.875-1.875l0,0h3.21 c0.605,0,1.036-0.125,1.597-0.568l6.283-5.391V21.455z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgSoundActive;

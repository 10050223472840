import * as React from 'react';
import { SVGProps } from 'react';

const SvgStandard3G = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.333,11.387c0.158-0.032,0.318-0.048,0.479-0.049c1.214,0,2.198,0.842,2.198,1.882 c0,0.794-0.625,1.77-1.945,1.77c-1.125,0-1.714-0.559-1.944-1.05 M6.991,8.956h3.766l-2.431,2.432 M15.25,12.206H17v1.5 c0.017,0.55-0.344,1.041-0.875,1.188C15.09,15.176,13,15.36,13,11.956c0-3.688,3.32-3.236,3.812-2.312"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,12c0,5.247-4.253,9.5-9.5,9.5S2.5,17.247,2.5,12S6.753,2.5,12,2.5h9.5V12z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgStandard3G;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgDocumentSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.355,2.543c-0.512,0-1.004,0.184-1.391,0.512l-2.703,2.32C5.789,5.777,5.52,6.371,5.52,6.992v12.465 c0,1.098,0.902,2,1.742,2h9.219c1.359,0,2-0.902,2-2V2.543H10.355z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.567,6.543H9.5V2.721"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.598,13.16h0.633v1.23c0,0-1.723,0.59-1.973-0.93c-0.25-1.539,0.73-1.855,0.922-1.902 c0.117-0.027,0.773-0.125,0.988,0.367 M7.523,14.305c0,0,0.363,0.301,1.023,0.258c0.332-0.02,0.797-0.23,0.719-0.836 c-0.043-0.199-0.164-0.375-0.332-0.492c-0.281-0.195-0.59-0.344-0.918-0.438c0,0-0.488-0.133-0.5-0.613 c-0.023-0.414,0.289-0.77,0.703-0.793h0.047c0.336-0.035,0.668,0.062,0.934,0.27 M10.648,11.551l1.137,2.902l1.07-2.902"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgDocumentSvg;

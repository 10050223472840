import * as React from 'react';
import { SVGProps } from 'react';

const SvgTv = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M7,19.466h10"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,19.466v-2"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.5,4.466h17v11c0,1.105-0.895,2-2,2h-15c-1.105,0-2-0.895-2-2v-9C2.5,5.362,3.395,4.466,4.5,4.466z"
        fill="none"
        strokeLinecap="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12}
        cy={14.998}
        r={0.154}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgTv;

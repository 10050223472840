import * as React from 'react';
import { SVGProps } from 'react';

const SvgNumber8 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,12.027c-0.016,5.242-4.258,9.484-9.5,9.5c-5.242-0.016-9.484-4.258-9.5-9.5 c0.016-5.238,4.258-9.484,9.5-9.5h9.5V12.027z"
        fill="none"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.5,14.207c0-1.254-1.121-2.273-2.5-2.273s-2.5,1.02-2.5,2.273c0,1.258,1.121,2.277,2.5,2.277 S14.5,15.465,14.5,14.207z M14.375,9.715c0-1.223-1.035-2.215-2.312-2.215S9.75,8.492,9.75,9.715c0,1.227,1.035,2.219,2.312,2.219 S14.375,10.941,14.375,9.715z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgNumber8;

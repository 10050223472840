import * as React from 'react';
import { SVGProps } from 'react';

const SvgVodafoneLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g fill="#ffffff">
      <path d="M12.038,0c1.773,0.001,3.525,0.394,5.129,1.15c-0.281-0.07-0.57-0.101-0.86-0.09 c-2.661,0.085-5.218,1.052-7.268,2.75C6.791,5.686,5.461,8.441,5.39,11.369c-0.174,3.862,2.815,7.133,6.676,7.307 c0.014,0.001,0.028,0.001,0.042,0.002c3.539,0.028,6.431-2.819,6.458-6.359c0-0.013,0-0.027,0-0.04c0-3-1.66-5.239-5.309-6.129 c-0.007-0.063-0.007-0.127,0-0.19c0.042-2.307,1.657-4.287,3.909-4.789c5.98,2.854,8.514,10.016,5.66,15.996 s-10.015,8.515-15.995,5.661s-8.514-10.016-5.66-15.996C3.17,2.645,7.401-0.015,12.038,0z" />
    </g>
  </svg>
);

export default SvgVodafoneLogo;

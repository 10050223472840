import * as React from 'react';
import { SVGProps } from 'react';

const SvgWarningWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#FFFFFF" fill="#FFFFFF">
      <path
        d="M23.37,17.845l0.082,0.194L14.881,2.989c-0.733-1.282-1.757-1.989-2.88-1.989s-2.147,0.707-2.88,1.992 l-8.49,14.853c-0.735,1.285-0.833,2.499-0.268,3.482s1.68,1.488,3.148,1.488H20.49c1.468,0,2.587-0.507,3.148-1.488 S24.105,19.131,23.37,17.845z M12.001,20.326L12.001,20.326c-0.596-0.003-1.076-0.495-1.073-1.099 c0.003-0.604,0.488-1.092,1.083-1.089c0.594,0.003,1.073,0.492,1.073,1.094v0c-0.001,0.605-0.485,1.095-1.082,1.094 C12.002,20.326,12.001,20.326,12.001,20.326L12.001,20.326z M13.076,6.61l-0.353,8.978l0,0c0,0.404-0.323,0.732-0.722,0.732 s-0.722-0.328-0.722-0.732l-0.357-8.976l0,0c-0.078-0.602,0.34-1.154,0.933-1.233c0.593-0.079,1.137,0.345,1.215,0.947 c0.012,0.095,0.012,0.191,0,0.285L13.076,6.61z"
        stroke="none"
      />
    </g>
  </svg>
);

export default SvgWarningWhite;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgSim = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.5,19.3V4.7c0-1.2,1-2.2,2.2-2.2H14l3.5,3.4v13.3c0,1.2-1,2.2-2.2,2.2H8.7C7.5,21.4,6.5,20.5,6.5,19.3z  M10.5,10.4h3c0.6,0,1,0.4,1,1v6c0,0.6-0.4,1-1,1h-3c-0.6,0-1-0.4-1-1v-6C9.5,10.9,9.9,10.4,10.5,10.4z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgSim;

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-alwa-tsx": () => import("./../../../src/pages/alwa.tsx" /* webpackChunkName: "component---src-pages-alwa-tsx" */),
  "component---src-pages-gigabeeprotect-tsx": () => import("./../../../src/pages/gigabeeprotect.tsx" /* webpackChunkName: "component---src-pages-gigabeeprotect-tsx" */),
  "component---src-pages-hydrosense-tsx": () => import("./../../../src/pages/hydrosense.tsx" /* webpackChunkName: "component---src-pages-hydrosense-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ion-tsx": () => import("./../../../src/pages/ion.tsx" /* webpackChunkName: "component---src-pages-ion-tsx" */),
  "component---src-pages-modbus-tsx": () => import("./../../../src/pages/modbus.tsx" /* webpackChunkName: "component---src-pages-modbus-tsx" */),
  "component---src-pages-papertowel-tsx": () => import("./../../../src/pages/papertowel.tsx" /* webpackChunkName: "component---src-pages-papertowel-tsx" */),
  "component---src-pages-push-to-talk-tsx": () => import("./../../../src/pages/pushToTalk.tsx" /* webpackChunkName: "component---src-pages-push-to-talk-tsx" */),
  "component---src-pages-sanitizer-tsx": () => import("./../../../src/pages/sanitizer.tsx" /* webpackChunkName: "component---src-pages-sanitizer-tsx" */)
}


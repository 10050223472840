import styled, { createGlobalStyle } from "styled-components";

export const HeroBackground = styled.main`
  background: linear-gradient(
    to top left,
    hsl(0, 100%, 25.1%) 0%,
    hsl(0, 100%, 25.35%) 8.1%,
    hsl(0, 100%, 26.07%) 15.5%,
    hsl(0, 100%, 27.18%) 22.5%,
    hsl(0, 100%, 28.61%) 29%,
    hsl(0, 100%, 30.28%) 35.3%,
    hsl(0, 100%, 32.14%) 41.2%,
    hsl(0, 100%, 34.1%) 47.1%,
    hsl(0, 100%, 36.1%) 52.9%,
    hsl(0, 100%, 38.06%) 58.8%,
    hsl(0, 100%, 39.91%) 64.7%,
    hsl(0, 100%, 41.59%) 71%,
    hsl(0, 100%, 43.02%) 77.5%,
    hsl(0, 100%, 44.13%) 84.5%,
    hsl(0, 100%, 44.84%) 91.9%,
    hsl(0, 100%, 45.1%) 100%
  );
  min-height: 100vh;
`;

export const CanvasStyles = createGlobalStyle`
  #tsparticles {
    width: 1200px;
    height: 600px;
    position: fixed;
    bottom: -150px;
    right: -330px;
    z-index: 0;
    transform: rotate(320deg);
  }
`;

import styled from "styled-components";
import { fontSize, spacing } from "../../../theme";
import { RectangularButton } from "../RectangularButton";

export const SmallButton = styled(RectangularButton)`
  ${fontSize("xxs", "small")};
  padding: ${spacing("xs", "m")};
  width: auto;
  display: inline-block;
`;

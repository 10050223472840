import React, { FC } from "react";
import * as S from "./Badge.styles";
import { BadgeProps } from "./Badge.types";

export const Badge: FC<BadgeProps> = ({ color, text, live, ...props }) => {
  return (
    <S.Badge
      color={color}
      live={live}
      {...props}
      dangerouslySetInnerHTML={{ __html: unescape(text ?? "") }}
    />
  );
};

import * as React from 'react';
import { SVGProps } from 'react';

const SvgRoaming = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.507,9.175l-4.394,1.563L4.056,9.574C3.8,9.45,3.504,9.433,3.235,9.526l-0.75,0.316l1.625,2 c1.196,1.432,2.968,2.384,4.73,1.77l9.829-3.459c2.191-0.885,2.581-1.929,2.851-2.985l0,0c-0.875-0.658-1.865-1.45-4.032-0.659 L13.799,7.9 M13.799,7.9 M11.512,10.196L7.3,5.918l1.174-0.409c0.318-0.111,0.667-0.088,0.969,0.062l6.045,3.231 M3.487,18.485 h17.044"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgRoaming;

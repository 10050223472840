import * as React from 'react';
import { SVGProps } from 'react';

const SvgBriefcase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.5,6.5h17v12.054c0,1.105-0.895,2-2,2h-15c-1.105,0-2-0.895-2-2V8.5C2.5,7.395,3.395,6.5,4.5,6.5z"
        fill="none"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M2.5,10.375l8.788,3.351c0.459,0.175,0.966,0.175,1.425,0l8.788-3.351"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.5,3.5h3c1.105,0,2,0.895,2,2v1h-7v-1C8.5,4.395,9.395,3.5,10.5,3.5z"
        fill="none"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgBriefcase;

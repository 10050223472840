import * as React from 'react';
import { SVGProps } from 'react';

const SvgRouter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.5,14.5h15v4c0,1.1-0.9,2-2,2h-13c-1.1,0-2-0.9-2-2v-2C3.5,15.4,4.4,14.5,5.5,14.5z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8,5.2c2.2-2.2,5.8-2.2,8,0l0,0 M9.6,6.5c1.3-1.3,3.5-1.3,4.9,0c0,0,0,0,0,0l0,0 M12,8v6.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12}
        cy={8}
        r={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={6}
        cy={17.5}
        r={0.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={8}
        cy={17.5}
        r={0.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgRouter;

import styled from "styled-components";
import { deviceUp } from "../Grid";

export const Toggle = styled.div`
  input[type="checkbox"] {
    position: relative;
    width: 28px;
    height: 20px;
    appearance: none;
    background: ${({ theme }) => theme.color.monochrome300};
    outline: none;
    border-radius: 12px;
    padding: 1px;
    cursor: pointer;

    @media ${deviceUp.m} {
      width: 40px;
      height: 24px;
      border-radius: 12px;
    }
  }

  input[type="checkbox"]::before {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${({ theme }) => theme.color.monochrome200};
    position: absolute;
    top: 2px;
    left: 2px;
    transition: 0.5s;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);

    @media ${deviceUp.m} {
      width: 22px;
      height: 22px;
      top: 1px;
      left: 1px;
    }
  }

  input[type="checkbox"]:checked::before {
    left: 10px;
    background: ${({ theme }) => theme.color.monochrome100};
    box-shadow: none;

    @media ${deviceUp.m} {
      left: 17px;
    }
  }

  input[type="checkbox"]:checked {
    background: ${({ theme }) => theme.color.secondary100};
  }
`;

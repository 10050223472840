import * as React from 'react';
import { SVGProps } from 'react';

const SvgCalculator = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <line
        vectorEffect="non-scaling-stroke"
        x1={6.7}
        y1={15.2}
        x2={8.8}
        y2={17.3}
        fill="none"
        strokeLinecap="round"
      />
      <line
        vectorEffect="non-scaling-stroke"
        x1={6.7}
        y1={17.3}
        x2={8.8}
        y2={15.2}
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5,3.5h15.5V19c0,0.8-0.7,1.5-1.5,1.5H5c-0.8,0-1.5-0.7-1.5-1.5V5C3.5,4.2,4.2,3.5,5,3.5z M12,20.5v-17 M3.6,12h16.9 M8,6.5v3 M6.5,8h3 M14.5,8h3 M14.5,17.5h3 M14.5,15.5h3"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgCalculator;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgRadiator = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M19.6,8.2c0.5,0,1,0.4,1,1c0,0.5-0.4,1-1,1 M18.7,8.2h1 M18.7,10.1h1 M7.2,8.2v7.6 M11,8.2v7.6 M14.9,8.2v7.6  M5.3,8.2v7.6 M9.1,8.2v7.6 M13,8.2v7.6 M16.8,8.2v7.6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.3,6.3h13.4v9.6c0,1.1-0.9,1.9-1.9,1.9H5.3c-1.1,0-1.9-0.9-1.9-1.9V8.2C3.4,7.1,4.3,6.3,5.3,6.3z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgRadiator;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgTurbidity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.2,16.3C6,17.8,7.1,19,8.5,19.1c1.5,0.2,2.7-0.9,2.8-2.3c0.2-1.5-0.9-2.7-2.3-2.8S6.4,14.9,6.2,16.3L6.2,16.3 z M5.3,11.9c-0.1,0.8,0.5,1.5,1.3,1.7c0.8,0.1,1.5-0.5,1.7-1.3S7.8,10.8,7,10.6S5.4,11.1,5.3,11.9z M5.4,16c0.1-0.6-0.3-1-0.9-1.1 s-1,0.3-1.1,0.9c-0.1,0.6,0.3,1,0.9,1.1C4.9,16.9,5.4,16.5,5.4,16z M11.4,7.4c-1.5,0-2.6,1.2-2.6,2.6s1.1,2.6,2.6,2.6S14,11.5,14,10 S12.8,7.4,11.4,7.4L11.4,7.4z M15.7,6c-0.8,0-1.5,0.6-1.5,1.5S14.9,9,15.7,9s1.5-0.6,1.5-1.5C17.2,6.7,16.6,6,15.7,6z M11.6,6.6 c0.6,0,1-0.5,1-1s-0.5-1-1-1s-1,0.5-1,1S11.1,6.6,11.6,6.6z M15.5,15.8c-0.2,1.5,0.9,2.7,2.3,2.8c1.5,0.2,2.7-0.9,2.8-2.3 c0.2-1.4-0.9-2.7-2.3-2.8C17,13.4,15.7,14.4,15.5,15.8L15.5,15.8z M14.6,11.4c-0.1,0.8,0.5,1.5,1.3,1.7c0.8,0.1,1.5-0.5,1.7-1.3 c0.1-0.8-0.5-1.5-1.3-1.7C15.4,9.9,14.7,10.6,14.6,11.4z M14.7,15.5c0.1-0.6-0.3-1-0.9-1.1c-0.6-0.1-1,0.3-1.1,0.9 c-0.1,0.6,0.3,1,0.9,1.1C14.1,16.5,14.7,16.1,14.7,15.5z M12.6,19.4c0.6,0.2,1.1-0.2,1.3-0.6c0.2-0.6-0.2-1.1-0.6-1.3 c-0.6-0.2-1.1,0.2-1.3,0.6C11.7,18.7,12,19.3,12.6,19.4z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgTurbidity;

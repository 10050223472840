import * as React from 'react';
import { SVGProps } from 'react';

const SvgActivity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.5,19.531c0,1.102-0.895,2-2,2h-13c-1.105,0-2-0.898-2-2v-13c0-1.105,0.895-2,2-2h15V19.531z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8,2.531v3 M20.5,7.566h-17 M16,2.531v3 M9.5,14.531h8 M9.5,11.531h8 M9.5,17.531h8"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={7}
        cy={14.531}
        r={0.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={7}
        cy={17.531}
        r={0.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={7}
        cy={11.531}
        r={0.125}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgActivity;

import { createGlobalStyle } from "styled-components";
import { deviceUp } from "../components/GlobalComponents";
import { fontSize } from "../theme";
import { reset } from "styled-reset";

export const GlobalStyles = createGlobalStyle`
  ${reset}

  html {
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.color.background};
    transition: background-color 0.3s;
  }

  body {
    color: ${({ theme }) => theme.color.monochrome600};
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: 'Vodafone', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-style: normal;
    ${fontSize("xxs", "body")}
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    @media ${deviceUp.m} {
      ${fontSize("m", "body")}
    }
  }

  i,
  em {
    font-style: normal;
  }

  * {
    box-sizing: inherit;

    &:before,
    &:after {
      box-sizing: inherit;
    }
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button:not(:disabled),
  [type='button']:not(:disabled),
  [type='reset']:not(:disabled),
  [type='submit']:not(:disabled) {
    cursor: pointer;
  }

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.monochrome600};

    &:hover {
      color: ${({ theme }) => theme.color.state100};
    }
  }

  b,
  strong {
    font-weight: ${({ theme }) => theme.text.weight.bold};
  }

  sup,
  sub {
    font-size: 0.75rem;
    line-height: 0;
    top: 0;
  }

  sup {
    vertical-align: super;
  }

  sup + sup,
  sub + sub {
    padding-right: 2px;
  }

  sub {
    vertical-align: sub;
  }
`;

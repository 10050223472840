import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "../config/config";
import { CustomNavigationClient } from "./NavigationClient";
import { MasterPageWrapper } from "../components/GlobalComponents";

const msalInstance = new PublicClientApplication(msalConfig);

const MSLAProvider = (children: { element: React.ReactNode }) => {
  // The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigationClient = new CustomNavigationClient();
  msalInstance.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={msalInstance}>
      <MasterPageWrapper>{children}</MasterPageWrapper>
    </MsalProvider>
  );
};

export default MSLAProvider;

import styled, { css } from "styled-components";
import { fontSize, spacing } from "../../../theme";
import { deviceUp } from "../Grid";
import { IconProps } from "../Icon";

export interface ButtonProps {
  variant?: string | null;
  iconPosition?: string | null;
  withBorder?: boolean;
  icon?: IconProps;
  fullWidth?: boolean;
}

// Icon spacing = space between icon & text + icon size
const iconSpacing = 26;

// Box shadow for tertiaryOpt1 to stay DRY
const tertiaryOpt1BoxShadow = css`
  box-shadow: 0 0 0 1px ${({ theme }) => theme.color.buttonTertiaryBorder} inset;
`;

export const RectangularButton = styled.button<ButtonProps>`
  border: none;
  display: block;
  width: 100%;
  text-decoration: none;
  text-align: center;
  ${fontSize("xxs", "h5")};
  padding: ${spacing("xs", "l")};
  border-radius: 6px;
  transition: background-color 0.15s ease-in-out;
  background-color: ${({ theme }) => theme.color.primary100};
  color: ${({ theme }) => theme.color.primary200};
  mask-image: radial-gradient(white, black);
  &.focus-visible {
    mask-image: none;
  }

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.color.primary200};
    background-color: ${({ theme }) => theme.color.secondary700};
  }

  &:visited {
    color: ${({ theme }) => theme.color.primary200};
  }

  @media ${deviceUp.s} {
    width: auto;
    display: inline-block;
    text-align: left;
    ${fontSize("m", "h5")};

    ${({ fullWidth }) =>
      fullWidth &&
      css`
        width: 100%;
        text-align: center;
      `};
  }

  > span {
    ${({ icon, iconPosition }) =>
      (icon?.as &&
        iconPosition === "End" &&
        `padding-right: ${spacing(iconSpacing)};`) ||
      (icon?.as &&
        iconPosition === "Start" &&
        `padding-left: ${spacing(iconSpacing)};`)};
    position: relative;
  }

  svg {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${({ iconPosition }) =>
      (iconPosition === "End" && `right: 0;`) ||
      (iconPosition === "Start" && `left: 0;`)};

    g {
      fill: ${({ theme }) => theme.color.primary200};
      stroke: ${({ theme }) => theme.color.primary200};
    }
  }

  ${({ variant }) => variant === "secondary" && secondaryMixin};
  ${({ variant }) => variant === "tertiary-opt1" && tertiaryOpt1Mixin};
  ${({ variant }) => variant === "tertiary-opt2" && tertiaryOpt2Mixin};
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.color.monochrome300};
      color: ${({ theme }) => theme.color.monochrome100};

      &:hover {
        background-color: ${({ theme }) => theme.color.monochrome300};
        color: ${({ theme }) => theme.color.monochrome100};
        cursor: default;
      }

      svg g {
        fill: ${({ theme }) => theme.color.monochrome100};
        stroke: ${({ theme }) => theme.color.monochrome100};
      }
    `};

  &:focus:not(:focus-visible):not(:active) {
    ${({ variant }) =>
      variant === "tertiary-opt1"
        ? tertiaryOpt1BoxShadow
        : css`
            box-shadow: none;
          `}
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 3px ${({ theme }) => theme.color.secondary100};
  }

  * {
    pointer-events: none;
  }
`;

export const secondaryMixin = css`
  background-color: ${({ theme }) => theme.color.monochrome400};
  color: ${({ theme }) => theme.color.monochrome100};

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.color.monochrome100};
    background-color: ${({ theme }) => theme.color.monochrome400};
  }

  svg g {
    fill: ${({ theme }) => theme.color.monochrome100};
    stroke: ${({ theme }) => theme.color.monochrome100};
  }
`;

export const tertiaryOpt1Mixin = css<ButtonProps>`
  background-color: transparent;
  color: ${({ theme }) => theme.color.monochrome600};
  border: 0;
  ${tertiaryOpt1BoxShadow}

  &:visited {
    color: ${({ theme }) => theme.color.monochrome600};
  }

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.color.monochrome600};
    background-color: ${({ theme }) => theme.color.buttonTertiaryBgHover};
    box-shadow: 0 0 0 1px
      ${({ theme }) => theme.color.buttonTertiaryBorderHover} inset;
  }

  svg g {
    fill: ${({ theme }) => theme.color.monochrome600};
    stroke: ${({ theme }) => theme.color.monochrome600};
  }
`;

export const tertiaryOpt2Mixin = css<ButtonProps>`
  background-color: ${({ theme }) => theme.color.primary200};
  color: ${({ theme }) => theme.color.darkFont};

  &:visited {
    color: ${({ theme }) => theme.color.darkFont};
  }

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.color.darkFont};
    background-color: ${({ theme }) => theme.color.primary200};
  }

  svg g {
    fill: ${({ theme }) => theme.color.darkFont};
    stroke: ${({ theme }) => theme.color.darkFont};
  }
`;

export const blockButtonMixin = css`
  @media ${deviceUp.m} {
    width: 100%;
    display: block;
    text-align: center;
  }
`;

export const ButtonContent = styled.span``;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgGigaDepot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d=" M13.741,10.244V7.34c0-0.322,0.259-0.581,0.581-0.581h4.065v3.484h-4.646V10.244z M18.387,10.243v7.621 c0,0.322-0.259,0.581-0.581,0.581h-3.484c-0.322,0-0.581-0.259-0.581-0.581v-7.621 M10.234,16.05H6.193 c-0.322,0-0.581-0.259-0.581-0.581v-2.323c0-0.322,0.259-0.581,0.581-0.581h4.041"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d=" M14.322,20.695h3.484"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d=" M19.816,23h-7.504c-1.148,0-2.078-0.994-2.078-2.141V5.371c0-1.148,0.93-2.078,2.078-2.078h9.581v17.565 C21.893,22.006,20.963,23,19.816,23z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d=" M7.387,18.41h2.841"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d=" M10.228,20.697H4.191c-1.148,0-2.078-0.943-2.078-2.085L2.107,3.067C2.102,2.521,2.325,1.993,2.715,1.605C3.1,1.217,3.631,1,4.18,1 h9.581v2.267"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgGigaDepot;

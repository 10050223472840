import * as React from 'react';
import { SVGProps } from 'react';

const SvgSmokedetector = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.4,10.2c0-1.9,3.8-3.5,8.6-3.5s8.6,1.6,8.6,3.5 M20.6,13.8c0-1.9-3.8-3.5-8.6-3.5s-8.6,1.6-8.6,3.5 M12,17.3 c4.8,0,8.6-1.6,8.6-3.5 M3.4,13.8c0,1.9,3.8,3.5,8.6,3.5 M3.4,10v3.7 M20.6,10.1v3.6 M5.2,13.9L5.2,13.9 M18.4,9.7 c-1.2-0.5-2.8-0.9-4.6-1.1 M10.1,8.6c-1.7,0.2-3.3,0.5-4.4,1"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgSmokedetector;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgPlayArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.5,4.7v14.5c0,0.7,0.5,1.2,1.2,1.3c0.3,0,0.6-0.1,0.8-0.2l0.2-0.1l9.3-6.9l0.3-0.2 c0.3-0.2,0.5-0.6,0.5-1s-0.2-0.8-0.6-1l-0.3-0.2l-9.2-7C8.3,3.6,7.8,3.4,7.3,3.6C6.8,3.8,6.5,4.3,6.5,4.8v0.1"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgPlayArrow;

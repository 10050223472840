import * as React from 'react';
import { SVGProps } from 'react';

const SvgShare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.391,12.004c0,1.352-1.094,2.445-2.445,2.445S2.5,13.355,2.5,12.004s1.094-2.449,2.445-2.449 S7.391,10.652,7.391,12.004z M21.445,4.996c0,1.352-1.094,2.449-2.445,2.449s-2.445-1.098-2.445-2.449S17.648,2.551,19,2.551 S21.445,3.645,21.445,4.996z M21.445,18.996c0,1.352-1.094,2.449-2.445,2.449s-2.445-1.098-2.445-2.449s1.094-2.445,2.445-2.445 S21.445,17.645,21.445,18.996z M16.812,17.902L7.148,13.07 M16.844,6.168L7.18,10.996"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgShare;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgAlarmAassistantFire = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g>
      <path
        vectorEffect="non-scaling-stroke"
        d=" M20.6,5.2c0,11.9-7,14.8-8.4,15.4c-0.1,0.1-0.3,0.1-0.4,0c-1.4-0.6-8.4-4.1-8.4-15c0-1.6,3.1,1.1,8.2-2.1c0.2-0.1,0.5-0.1,0.8,0 c2,1.3,3.7,1.7,5,1.7H20.6C20.6,5.2,20.6,5.2,20.6,5.2z"
        fill="none"
        stroke="#0D0D0D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.7,10.7 c0.7-1,1.8-1.4,1.8-1.4c-0.4,0.7-0.4,1.5,0,2.2s0.9,1.2,0.9,2.1c0,1.5-1.6,2.2-2.5,2.4c-5.6,0.8-4.3-4.4-4.3-4.4 c0.9,0.9,1.8,0.5,1.8,0.5C9.9,9.4,11.7,8,11.7,8C11.9,8.8,11.8,10.1,12.7,10.7"
        fill="none"
        stroke="#0D0D0D"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgAlarmAassistantFire;

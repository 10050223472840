import * as React from 'react';
import { SVGProps } from 'react';

const SvgRenewableEnergy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.1,11.2l-1,9.4H13C13,20.6,12.1,11.2,12.1,11.2z"
        fill="none"
        stroke="#0D0D0D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12.1}
        cy={10.2}
        r={1}
        fill="none"
        stroke="#0D0D0D"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.1,9.2V3.4 M11.2,10.6l-5.5,2 M13,10.6l5.3,2.1"
        fill="none"
        stroke="#0D0D0D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgRenewableEnergy;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgMeal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.9,11.1v7.6 M13.9,5.3v3.8 M15.8,5.3v4.3c0,0.8-0.6,1.4-1.4,1.4h-1c-0.8,0-1.4-0.6-1.4-1.4V5.3 M19.2,13.9 v4.8 M12,17c-2.7,1.6-6.2,0.6-7.8-2.1S3.5,8.6,6.3,7.1C8,6.1,9.8,6.3,10.1,6.4 M6.7,9c-1.6,1.3-1.9,3.7-0.6,5.4 c0.4,0.5,0.9,0.9,1.6,1.2 M20.6,5.3L20.6,5.3c-3.1,0-2.9,4.7-2.9,8.1v0.1c0,0.3,0.2,0.5,0.5,0.5h1.9c0.3,0,0.5-0.2,0.5-0.5V5.3z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgMeal;

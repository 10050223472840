import * as React from 'react';
import { SVGProps } from 'react';

const SvgNumber0 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,12c-0.016,5.24-4.26,9.484-9.5,9.5l0,0c-5.24-0.016-9.484-4.26-9.5-9.5l0,0 C2.516,6.76,6.76,2.516,12,2.5h9.5V12z"
        fill="none"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,7.47c1.56,0,2.82,1.72,2.82,4.5c0,2.38-1,4.5-2.82,4.5l0,0c-1.82,0-2.82-2.12-2.82-4.5 C9.18,9.2,10.44,7.47,12,7.47z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgNumber0;

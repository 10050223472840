import * as React from 'react';
import { SVGProps } from 'react';

const SvgCamera = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12}
        cy={12}
        r={3.984}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.377,5.975V4.909c0-0.313,0.254-0.567,0.567-0.567h2.836c0.313,0,0.567,0.254,0.567,0.567v1.066  M15.377,5.975H3.518c-1.253,0-2.269,1.016-2.269,2.269v9.145c0,1.253,1.016,2.269,2.269,2.269h16.963 c1.253,0,2.269-1.016,2.269-2.269V5.975h-3.403"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgCamera;

import styled from "styled-components";
import { BadgeStyleProps } from "./Badge.types";
import { DefaultTheme, fontSize, spacing } from "../../../theme";
import { deviceUp } from "../Grid";

const fontColorForButtonColor = (
  buttonColor: string | undefined,
  theme: DefaultTheme
) => {
  switch (buttonColor) {
    case "secondary400":
    case "primary200":
      return theme.color.darkFont;

    case "secondary500":
      return theme.color.monochrome100;

    default:
      return theme.color.primary200;
  }
};

export const Badge = styled.div<BadgeStyleProps>`
  border-radius: 20px;
  color: ${({ color, theme }) => fontColorForButtonColor(color, theme)};
  background-color: ${({ color, theme }) => theme.color[color]};
  padding: ${spacing(0, "s")};
  display: inline-block;
  animation: liveanim 2s infinite;

  @keyframes liveanim {
    0% {
      box-shadow: ${({ theme }) =>
        `0 0 0 0 ${theme.color.bgPanel200}, 0 0 0 0 ${theme.color.secondary700}`};
    }

    60% {
      box-shadow: ${({ theme }) =>
        `0 0 0 2px ${theme.color.bgPanel200}, 0 0 0 3px ${theme.color.secondary700}`};
    }

    100% {
      box-shadow: ${({ theme }) =>
        `0 0 0 0 ${theme.color.bgPanel200}, 0 0 0 0 ${theme.color.secondary700}`};
    }
  }

  ${fontSize("xxs", "badge")};

  @media ${deviceUp.m} {
    ${fontSize("m", "badge")};
  }

  @media ${deviceUp.l} {
    ${fontSize("l", "badge")};
  }
`;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgDocumentPng = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.355,2.543c-0.512,0-1.004,0.184-1.391,0.512l-2.703,2.32C5.789,5.777,5.52,6.371,5.52,6.992v12.465 c0,1.098,0.902,2,1.742,2h9.219c1.359,0,2-0.902,2-2V2.543H10.355z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.567,6.543H9.5V2.721"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.598,13.227h0.633v1.23c0,0-1.723,0.59-1.973-0.926c-0.25-1.539,0.73-1.859,0.922-1.902 c0.117-0.027,0.773-0.125,0.988,0.367 M7.77,14.566v-2.965h0.867c0,0,0.863-0.062,0.863,0.867s-0.867,0.867-0.867,0.867H7.77  M10.871,14.57v-2.969l1.98,2.969v-2.969"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgDocumentPng;

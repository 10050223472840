import * as React from 'react';
import { SVGProps } from 'react';

const SvgIot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,14.516v3.451 M15.385,6.316c-0.76-1.918-2.685-3.331-4.736-3.331c-2.375,0-4.234,1.908-4.59,4.275h0.006 C4.552,7.714,3.5,9.197,3.5,10.875c0,2.012,1.74,3.644,3.75,3.644h9.084c2.251,0.001,4.077-1.822,4.08-4.073 c0-2.25-1.384-4.195-4.316-4.195c-0.238,0-0.476,0.022-0.71,0.068L15.385,6.316z M14.5,14.515v2.522c0.003,0.55,0.45,0.994,1,0.994 h1.075 M7.434,18.031h1.075c0.55,0,0.997-0.444,1-0.994v-2.521 M5.934,16.516c0.828,0,1.5,0.672,1.5,1.5s-0.672,1.5-1.5,1.5 s-1.5-0.672-1.5-1.5S5.105,16.516,5.934,16.516z M12.002,18.016c0.828,0,1.5,0.672,1.5,1.5s-0.672,1.5-1.5,1.5s-1.5-0.672-1.5-1.5 S11.174,18.016,12.002,18.016z M18.07,16.516c0.828,0,1.5,0.672,1.5,1.5s-0.672,1.5-1.5,1.5s-1.5-0.672-1.5-1.5 S17.242,16.516,18.07,16.516z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgIot;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgFacebookBoxed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g fill="#ffffff">
      <path d="M23,12c0-6.1-4.9-11-11-11S1,5.9,1,12c0,5.4,3.9,10,9.3,10.9v-7.7H7.5V12h2.8V9.6c0-2.8,1.6-4.3,4.2-4.3 c0.8,0,1.6,0.1,2.5,0.2v2.7h-1.4c-0.9-0.1-1.7,0.5-1.8,1.4c0,0.1,0,0.2,0,0.3V12h3.1l-0.5,3.2h-2.6v7.7C19.1,22,23,17.4,23,12z" />
    </g>
  </svg>
);

export default SvgFacebookBoxed;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgIp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.367,12.282h1.753c0.484-0.012,0.919-0.145,1.257-0.52c0.23-0.266,0.351-0.568,0.375-0.919 c0.012-0.314,0.024-0.629-0.097-0.919c-0.218-0.568-0.665-0.858-1.233-0.919c-0.592-0.06-1.185-0.012-1.777-0.024 c-0.218,0-0.278,0.06-0.278,0.278v5.766 M9.421,8.981v6.02"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,12c0,5.247-4.253,9.5-9.5,9.5S2.5,17.247,2.5,12S6.753,2.5,12,2.5h9.5V12z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgIp;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgUnifiedCommunications = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.43,5.941l-0.156-0.047c-0.426-0.109-0.863-0.152-1.305-0.125s-0.875,0.121-1.285,0.285 c-0.375,0.148-0.723,0.359-1.031,0.625c-0.281,0.242-0.508,0.539-0.668,0.875c-0.32,0.645-0.281,1.41,0.098,2.023 c0.195,0.309,0.449,0.574,0.75,0.781c0.332,0.223,0.699,0.391,1.09,0.492c0.426,0.109,0.863,0.152,1.305,0.125h0.043 c0.148,0.098,0.305,0.188,0.465,0.266c0.195,0.098,0.398,0.172,0.609,0.23c0.246,0.066,0.496,0.105,0.75,0.117 c0.293,0.012,0.586-0.016,0.871-0.074c0,0-0.914-0.527-0.938-1.07c0.215-0.129,0.414-0.277,0.598-0.445 c0.172-0.164,0.324-0.344,0.453-0.543c0.121-0.191,0.211-0.398,0.273-0.617c0.16-0.562,0.074-1.164-0.238-1.66 c-0.125-0.207-0.277-0.395-0.457-0.555c-0.367-0.293-0.781-0.523-1.227-0.68 M20.047,5.844l0.051-0.328 c0.098-0.656-0.332-1.277-0.332-1.277c-0.25-0.398-0.582-0.738-0.969-1c-0.426-0.289-0.898-0.504-1.398-0.633 c-0.547-0.141-1.113-0.195-1.68-0.16s-1.125,0.156-1.652,0.367C13.582,3,13.137,3.273,12.742,3.609 c-0.359,0.309-0.656,0.687-0.867,1.113c-0.199,0.414-0.293,0.867-0.27,1.324c0.012,0.285,0.074,0.57,0.18,0.836 c0.109,0.27,0.254,0.52,0.438,0.742c0.188,0.234,0.41,0.445,0.652,0.625c0.25,0.184,0.762,0.41,1.062,0.539 c-0.391,0.914-1.297,1.453-1.297,1.453c0.375,0.035,0.75,0.02,1.125-0.043C14.02,10.156,14.273,10.09,14.52,10 c0.41-0.172,0.809-0.371,1.188-0.594l0.227-0.125"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.492,17.215c0.012,0.031,0.016,0.066,0.016,0.105 c-0.004,0.328-0.055,0.652-0.152,0.969c-0.375,1.215-1.387,2.523-2.582,2.875c-3.016,0.898-6.191-0.023-9-1.434 c-2.32-1.16-4.211-3.035-5.387-5.348c-1.414-2.797-2.34-5.957-1.438-8.957C3.305,4.238,4.625,3.23,5.844,2.859l0.98-0.258 c0.164-0.043,0.328,0.047,0.383,0.203L7.98,5.07c0.395,1.102,0.79,2.203,1.184,3.305l0.027,0.086 c0.055,0.152,0.008,0.324-0.125,0.426c-0.383,0.289-0.812,0.516-1.27,0.664C7.254,9.719,6.691,9.828,6.125,9.875 c-0.109,0.012-0.207,0.07-0.27,0.164c-0.059,0.09-0.074,0.203-0.043,0.309c0.594,1.875,1.625,3.582,3.012,4.977 c1.402,1.387,3.117,2.418,5,3.004c0.105,0.035,0.219,0.02,0.309-0.043c0.09-0.059,0.152-0.156,0.164-0.266 c0.047-0.566,0.156-1.129,0.328-1.672c0.152-0.457,0.379-0.883,0.672-1.262c0.098-0.133,0.273-0.184,0.426-0.125l0.086,0.027 l3.316,1.18c0.789,0.277,1.461,0.559,2.25,0.84C21.422,17.023,21.469,17.141,21.492,17.215z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgUnifiedCommunications;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgSave = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.617,3.477H5.453c-1.105,0-2,0.895-2,2v13.109c0,1.105,0.895,2,2,2h13.102c1.105,0,2-0.895,2-2V7.031 L17.617,3.477z"
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.453,12.547h11.094v8.039H6.453V12.547z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8,3.477v4.148c0,0.551,0.449,1,1,1h6c0.551,0,1-0.449,1-1V3.477"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgSave;

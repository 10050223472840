import * as React from 'react';
import { SVGProps } from 'react';

const SvgPopOut = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <polyline
        vectorEffect="non-scaling-stroke"
        points="14.5,3.5 20.5,3.5 20.5,9.5 "
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        vectorEffect="non-scaling-stroke"
        x1={13.5}
        y1={10.5}
        x2={20.5}
        y2={3.5}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.5,13v5.5c0,1.105-0.895,2-2,2h-13c-1.105,0-2-0.895-2-2v-13c0-1.105,0.895-2,2-2H11"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgPopOut;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgLike = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.5,21.5h9.699c1.438,0,2.625-1.117,1.98-2.68c1.469-1.695,0.695-2.977,0.695-2.977s1.391-1.508,0.039-2.973 c0.352-0.98,0.551-1.852-0.727-2.719c-1.281-0.867-4.324-0.027-5.438,0.113c-1.484-0.473,2.949-7.641-1.77-7.766 c-0.02,0.051-0.551-0.125-0.402,1.734c0,1.941-3.66,6.266-4.859,7.945"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.5,21.5H5c-1.379,0-2.5-1.121-2.5-2.5v-5c0-1.379,1.121-2.5,2.5-2.5h1c1.379,0,2.5,1.121,2.5,2.5V21.5z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgLike;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgNumber3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.5,12.027c-0.016,5.242-4.258,9.484-9.5,9.5c-5.242-0.016-9.484-4.258-9.5-9.5 c0.016-5.238,4.258-9.484,9.5-9.5h9.5V12.027z"
        fill="none"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.277,7.5h5.387l-3.477,3.477l0.012,0.012c0.227-0.043,0.453-0.062,0.684-0.062 c1.734,0,3.141,1.203,3.141,2.691c0,1.484-1.406,2.875-3.141,2.875c-1.062,0.027-2.059-0.52-2.602-1.438"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgNumber3;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgInstaBoxed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g fill="#ffffff">
      <path d="M12,3.8c2.7,0,3,0,4,0.1c0.6,0,1.3,0.1,1.9,0.4c0.9,0.3,1.6,1,1.9,1.9C20,6.7,20.1,7.4,20.1,8 c0,1,0.1,1.4,0.1,4s0,3-0.1,4c0,0.6-0.1,1.3-0.4,1.9c-0.3,0.9-1,1.6-1.9,1.9c-0.6,0.2-1.2,0.3-1.9,0.3c-1,0-1.4,0.1-4,0.1 s-3,0-4-0.1c-0.7,0-1.3-0.1-1.9-0.3c-0.9-0.3-1.6-1-1.9-1.9C4,17.3,3.9,16.6,3.9,16c0-1-0.1-1.4-0.1-4s0-3,0.1-4 c0-0.6,0.1-1.3,0.4-1.9c0.3-0.9,1-1.6,1.9-1.9C6.7,4,7.4,3.9,8,3.9C9,3.9,9.4,3.8,12,3.8 M12,2C9.3,2,9,2,7.9,2.1 C7,2.1,6.2,2.2,5.4,2.5C4.1,3,3,4.1,2.5,5.4C2.2,6.2,2.1,7,2.1,7.9C2,8.9,2,9.3,2,12s0,3.1,0.1,4.1c0,0.8,0.2,1.7,0.5,2.4 C3,19.9,4.1,21,5.4,21.5c0.8,0.3,1.6,0.5,2.4,0.5C8.9,22,9.3,22,12,22s3.1,0,4.1-0.1c0.8,0,1.7-0.2,2.4-0.5 c1.3-0.5,2.4-1.6,2.9-2.9c0.3-0.8,0.5-1.6,0.5-2.4c0-1.1,0.1-1.4,0.1-4.1s0-3.1-0.1-4.1c0-0.8-0.2-1.7-0.5-2.4 C21,4.1,19.9,3,18.5,2.5c-0.8-0.3-1.6-0.5-2.4-0.5C15.1,2,14.7,2,12,2z M12,6.9c-2.8,0-5.1,2.3-5.1,5.1s2.3,5.1,5.1,5.1 s5.1-2.3,5.1-5.1l0,0C17.1,9.2,14.8,6.9,12,6.9z M12,15.3c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3l0,0 C15.3,13.8,13.8,15.3,12,15.3z M17.3,7.9c0.7,0,1.2-0.5,1.2-1.2S18,5.5,17.3,5.5c-0.7,0-1.2,0.5-1.2,1.2S16.7,7.9,17.3,7.9 C17.3,7.9,17.3,7.9,17.3,7.9z" />
    </g>
  </svg>
);

export default SvgInstaBoxed;

import React, { createRef, useContext, useState } from "react";
import * as Styled from "./AccordionItem.styles";
import { useRipple } from "react-use-ripple";
import { AccordionItemProps } from "./AccordionItem.types";
import { Collapse, UnmountClosed } from "react-collapse";
import { ThemeContext } from "styled-components";
import { Headline } from "../Headline";
import { Icon } from "../Icon";

const collapseTheme = {
  collapse: "wrapper--collapse",
};

export const AccordionItem: React.FC<AccordionItemProps> = ({
  header,
  children,
  open,
  headlineIcon,
  hideDivider,
  backgroundColor = "bgPanel100",
  headlineBold,
  styleAs = "h5",
  unmountOnCollapse = false,
  allowShadow = false,
  ...props
}) => {
  const [isOpen, setIsCollapsed] = useState<boolean>(!!open);
  const handleClick = () => setIsCollapsed((state) => !state);
  const ref = createRef<HTMLButtonElement>();
  const theme = useContext(ThemeContext);
  useRipple(ref, {
    rippleColor: theme.color.monochrome300,
  });
  const CollapseElement = unmountOnCollapse ? UnmountClosed : Collapse;

  return (
    <Styled.AccordionItem
      background={backgroundColor}
      boxShadow={allowShadow}
      noPaddingX={true}
      noPaddingY={true}
      variant={"medium"}
      {...{
        ...props,
      }}
    >
      {header && typeof header === "string" ? (
        <>
          <Styled.HeadlineContainer onClick={handleClick} ref={ref}>
            {headlineIcon && (
              <Styled.TextIconContainer>
                <Icon as={headlineIcon} />
              </Styled.TextIconContainer>
            )}
            <Headline
              as={"span"}
              styleAs={styleAs}
              bold={headlineBold}
              text={header}
            />
            <Styled.IconContainer iconRotate={isOpen}>
              <Icon as="ChevronDown" tabIndex={0} />
            </Styled.IconContainer>
          </Styled.HeadlineContainer>
          <Styled.Divider visible={!hideDivider && isOpen} />
        </>
      ) : (
        React.isValidElement(header) &&
        React.cloneElement(header, { onClick: handleClick, isOpen })
      )}
      <CollapseElement theme={collapseTheme} isOpened={!header || isOpen}>
        <Styled.TextContainer>{children}</Styled.TextContainer>
      </CollapseElement>
    </Styled.AccordionItem>
  );
};

import styled, { css } from "styled-components";
import { deviceUp } from "../Grid";
import { fontSize } from "../../../theme";

export type HeadlineMixinProps = {
  styleAs: "h1" | "h2" | "h3" | "h4" | "h5" | "body";
  align?: "left" | "center" | "right";
  bold?: boolean;
};

export const headlineMixin = ({
  styleAs,
  align,
  bold,
}: HeadlineMixinProps) => css`
  display: block;
  margin: 0;
  padding: 0;
  font-weight: ${({ theme }) =>
    bold
      ? theme.text.weight[XXS_BOLD_MAPPING[styleAs]]
      : theme.text.weight[XXS_WEIGHT_MAPPING[styleAs]]};
  ${fontSize("xxs", styleAs)};
  text-align: ${align};
  hyphens: manual;

  @media ${deviceUp.m} {
    font-weight: ${({ theme }) =>
      bold
        ? theme.text.weight[M_BOLD_MAPPING[styleAs]]
        : theme.text.weight[M_WEIGHT_MAPPING[styleAs]]};
    ${fontSize("m", styleAs)};
  }

  @media ${deviceUp.l} {
    ${fontSize("l", styleAs)};
  }
`;

export const Headline = styled.h1<HeadlineMixinProps>`
  ${headlineMixin};
`;

const XXS_WEIGHT_MAPPING: {
  [key: string]: "light" | "normal" | "bold";
} = {
  h1: "light",
  h2: "light",
  h3: "light",
  h4: "normal",
  h5: "normal",
  body: "bold",
};

const M_WEIGHT_MAPPING: {
  [key: string]: "light" | "normal" | "bold";
} = {
  h1: "light",
  h2: "light",
  h3: "light",
  h4: "light",
  h5: "normal",
  body: "bold",
};

const XXS_BOLD_MAPPING: {
  [key: string]: "light" | "normal" | "bold";
} = {
  h1: "light",
  h2: "bold",
  h3: "bold",
  h4: "bold",
  h5: "bold",
  body: "bold",
};

const M_BOLD_MAPPING: {
  [key: string]: "light" | "normal" | "bold";
} = {
  h1: "light",
  h2: "bold",
  h3: "light",
  h4: "bold",
  h5: "bold",
  body: "bold",
};

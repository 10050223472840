import * as React from 'react';
import { SVGProps } from 'react';

const SvgTickGrey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#CCCCCC" fill="#CCCCCC">
      <circle cx={12} cy={12} r={12} stroke="none" />
      <path
        d="M9.1,18.05c-0.208,0-0.407-0.087-0.549-0.238l-4.1-4.4c-0.282-0.304-0.266-0.778,0.038-1.061 c0.303-0.282,0.777-0.266,1.06,0.038l3.548,3.808l8.651-9.405c0.28-0.305,0.755-0.324,1.06-0.044 c0.305,0.28,0.324,0.755,0.044,1.06l-9.2,10C9.51,17.962,9.329,18.092,9.1,18.05z"
        stroke="none"
        fill="#ffffff"
      />
    </g>
  </svg>
);

export default SvgTickGrey;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.4,8.8l-3.2-3.3L4.6,16.1l-1.1,4.4l4.4-1.1L18.4,8.8 M15.2,5.5l1.6-1.6c0.5-0.5,1.4-0.5,1.9,0c0,0,0,0,0,0 L21,6.2l-2.6,2.6 M4.9,15.8L8.2,19"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgEdit;

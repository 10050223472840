import * as React from 'react';
import { SVGProps } from 'react';

const SvgThermometer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.7,8.8v6.2 M9.7,15.2c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8S10.7,15.2,9.7,15.2z M16.7,5.9 L16.7,5.9c1.7,1.7,1.7,4.3,0,6 M15.4,7.1c1,1,1,2.5,0,3.5 M14,8.9c0,0.1-0.1,0.2-0.2,0.2l0,0c-0.1,0-0.2-0.1-0.2-0.2 c0-0.1,0.1-0.2,0.2-0.2S14,8.7,14,8.9L14,8.9z M13.9,8.9c0,0.1-0.1,0.1-0.1,0.1l0,0c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1 S13.9,8.8,13.9,8.9L13.9,8.9z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.5,13.8c1.1,0.6,1.8,1.8,1.8,3.1c0,2-1.6,3.6-3.6,3.6S6.1,19,6.1,17c0-1.3,0.7-2.5,1.8-3.1 M11.5,13.8V3.4 h-1.3c-1.3,0-2.4,1.1-2.4,2.4v8.1"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgThermometer;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.016,21.5c-0.945-0.59-1.848-1.254-2.695-1.98c-2.305-1.93-4.52-4.238-5.828-6.988 C2.266,9.953,1.906,6,4.164,3.863c1.141-1.082,2.727-1.562,4.273-1.289c1.551,0.27,2.879,1.258,3.582,2.664 M12.02,21.5 c0.945-0.59,1.848-1.254,2.695-1.98c2.305-1.93,4.52-4.238,5.832-6.988C21.773,9.953,22.125,6,19.875,3.863 c-1.141-1.082-2.727-1.562-4.273-1.289c-1.547,0.27-2.875,1.262-3.582,2.664 M12.484,6.59C12.031,4.258,10.012,2.5,7.594,2.5"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgHeart;

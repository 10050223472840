import * as React from 'react';
import { SVGProps } from 'react';

const SvgPlaceholder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.7,5.3L5.3,18.7 M5.3,5.3l13.4,13.4 M2.5,12h19 M12,2.5v19"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,2.5c5.2,0,9.5,4.3,9.5,9.5s-4.3,9.5-9.5,9.5S2.5,17.2,2.5,12S6.8,2.5,12,2.5z M4.5,2.5h17v17c0,1.1-0.9,2-2,2h-15c-1.1,0-2-0.9-2-2v-15c0-0.5,0.2-1,0.6-1.4S4,2.5,4.5,2.5z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgPlaceholder;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgCloud = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.986,18.5c0,0,0.669,0.024,1.705-0.398c1.571-0.639,2.618-2.274,2.785-3.961 c0.179-1.651-0.593-3.26-1.994-4.152c-0.7-0.436-1.519-0.613-2.337-0.685c-0.467-0.012-0.934,0.009-1.398,0.064 c-0.131-0.418-0.31-0.82-0.534-1.196c-0.889-1.5-2.534-2.526-4.206-2.656C9.299,5.391,7.636,6.101,6.544,7.421 C6.013,8.064,5.633,8.817,5.43,9.625c-0.054,0.214-0.16,0.811-0.16,0.811c-0.548,0.18-1.053,0.471-1.483,0.855 c-1.314,1.186-1.634,3.135-0.899,4.73c0.668,1.448,2.055,2.479,3.65,2.479H16.986z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgCloud;

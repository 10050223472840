import * as React from 'react';
import { SVGProps } from 'react';

const SvgDeals = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.3,16.7c0.3,0.4,0.4,1,0.2,1.4l0,0c-0.3,0.6-1.1,0.8-1.7,0.5l-4.3-2.5 M15.042,18.159 c0.267,0.398,0.349,0.965,0.158,1.441l0,0c-0.2,0.3-0.4,0.5-0.8,0.6c-0.3,0.1-0.7,0-0.9-0.1L13.3,20L5,15.2l-0.1-0.1 c-2.4-1.4-3.2-4.4-1.8-6.8l1.2-2.1c0.7-1.1,1.7-2,3-2.3s2.6-0.2,3.8,0.5l0.4,0.2 M20.6,12.8c0.3,0.2,0.5,0.4,0.6,0.8 c0.1,0.3,0,0.7-0.1,0.9l0,0c-0.3,0.6-1.1,0.8-1.7,0.5L14,11.8 M20.7,12.5c0.4-0.7,0.6-1.5,0.7-2.3c0.1-1.8,0-6.5,0-6.5 s-6.4,0-7.3,0.1c-1.7,0.1-3.3,1-4.1,2.5l-3.3,5.4v0.1c0,0,0,0.1,0.1,0.1l0.8,0.3c0.5,0.2,1.1,0.1,1.6-0.3l2.1-2.3 c1.5,0.6,2.5,0.2,3.3-0.2l6,3.5L20.7,12.5z M19.564,15.068c0.159,0.179,0.247,0.366,0.336,0.632c0.1,0.3,0,0.7-0.1,0.9l0,0 c-0.2,0.3-0.4,0.5-0.8,0.6c-0.3,0.1-0.7,0-0.9-0.1L12.7,14"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgDeals;

import styled, { css } from "styled-components";
import {
  Col as ColStyled,
  Grid as GridStyled,
  Row as RowStyled,
} from "react-styled-flexboxgrid";
import { spacing } from "../../../theme";
import { Dimensions } from "../../../theme/dimensions";
import {
  breakpointsInPixel,
  containerMaxWidth,
  spaces,
} from "../../../theme/layout";

export const maxWidth = {
  [Dimensions.xs]: `${containerMaxWidth.xs}px`,
  [Dimensions.s]: `${containerMaxWidth.s}px`,
  [Dimensions.m]: `${containerMaxWidth.m}px`,
  [Dimensions.l]: `${containerMaxWidth.l}px`,
  [Dimensions.xl]: `${containerMaxWidth.xl}px`,
  [Dimensions.xxl]: `${containerMaxWidth.xxl}px`,
};

export const deviceUp = {
  [Dimensions.xs]: `(min-width: ${breakpointsInPixel.xs}px)`,
  [Dimensions.s]: `(min-width: ${breakpointsInPixel.s}px)`,
  [Dimensions.m]: `(min-width: ${breakpointsInPixel.m}px)`,
  [Dimensions.l]: `(min-width: ${breakpointsInPixel.l}px)`,
  [Dimensions.xl]: `(min-width: ${breakpointsInPixel.xl}px)`,
};

export const deviceDown = {
  [Dimensions.xs]: `(max-width: ${containerMaxWidth.xs}px)`,
  [Dimensions.s]: `(max-width: ${containerMaxWidth.s}px)`,
  [Dimensions.m]: `(max-width: ${containerMaxWidth.m}px)`,
  [Dimensions.l]: `(max-width: ${containerMaxWidth.l}px)`,
  [Dimensions.xl]: `(max-width: ${containerMaxWidth.xl}px)`,
};

export const Grid = styled(GridStyled)`
  padding-left: ${spacing("m")};
  padding-right: ${spacing("m")};
  width: 100%;
  max-width: ${maxWidth.m};

  @media ${deviceUp.m} {
    padding-left: ${spacing("l")};
    padding-right: ${spacing("l")};
    max-width: ${maxWidth.l};
  }

  @media ${deviceUp.l} {
    max-width: ${maxWidth.xl};
  }
`;

export const Row = styled(RowStyled)`
  margin-left: -${spaces.m / 2}px;
  margin-right: -${spaces.m / 2}px;

  @media ${deviceUp.m} {
    margin-left: -${spaces.l / 2}px;
    margin-right: -${spaces.l / 2}px;
  }
`;
export const ModbusRow = styled(RowStyled)`
  display:flex;
  flex-wrap:nowrap;
  width: 100%;
  margin-left: -${spaces.m / 2}px;
  margin-right: -${spaces.m / 2}px;

  @media ${deviceUp.m} {
    margin-left: -${spaces.l / 2}px;
    margin-right: -${spaces.l / 2}px;
  }
`;

export const Col = styled(ColStyled)`
  padding-left: ${spaces.m / 2}px;
  padding-right: ${spaces.m / 2}px;

  @media ${deviceUp.m} {
    padding-left: ${spaces.l / 2}px;
    padding-right: ${spaces.l / 2}px;
  }
`;

/**
 * This mixin is meant to be used if you don't want any spacing in the grid whatsoever.
 */
export const noGuttersMixin = css`
  padding-left: 0;
  padding-right: 0;

  @media ${deviceUp.m} {
    padding-left: 0;
    padding-right: 0;
  }

  & > ${Row} {
    margin-right: 0;
    margin-left: 0;

    @media ${deviceUp.m} {
      margin-right: 0;
      margin-left: 0;
    }

    & > ${Col} {
      padding-left: 0;
      padding-right: 0;

      @media ${deviceUp.m} {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`;

/**
 * This mixin is meant to be used if you don't want any border-spacing to the LEFT side
 * of the container on desktop devices.
 * On mobile & tablet devices the first Column will be full width (no spacing on both sides).
 * The last column will have the normal spacing towards the sides of the screen.
 *
 * Comment from David Kox, June 2021:
 * If there are more than 2 columns for e.g. you want a full width image with two columns
 * underneath - this mixin would cause a double-width gutter spacing for the two columns under the image.
 * This could be solved by splitting the paddings with "2nd" & "last" operators in the Col.
 * For now we decided that it would be too much overhead to implement it as no use case is in sight.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const noGuttersLeftMixin = (reverseOnDesktop?: boolean) => css`
  padding-left: 0;

  @media ${deviceUp.m} {
    padding-left: 0;
  }

  @media ${deviceDown.l} {
    padding-right: 0;
  }

  & > ${Row} {
    margin-left: 0;

    @media ${deviceUp.m} {
      margin-left: 0;
      margin-right: -${spaces.l / 2}px;
    }
    @media ${deviceDown.l} {
      margin-right: 0;
    }

    & > ${Col} {
      padding-left: ${spaces.m}px;
      padding-right: ${spaces.m}px;

      @media ${deviceUp.m} {
        padding-left: ${spaces.l}px;
        padding-right: ${spaces.l}px;
      }

      @media ${deviceUp.l} {
        padding-left: ${spaces.l / 2}px;
        padding-right: ${spaces.l / 2}px;
      }

      ${reverseOnDesktop ? reverseDesktopLeft : rowDesktopLeft}
    }
  }
`;

const reverseDesktopLeft = css`
  &:first-of-type {
    padding-left: 0;

    @media ${deviceUp.m} {
      padding-left: 0;
    }

    @media ${deviceDown.l} {
      padding-right: 0;
    }
  }
`;

const rowDesktopLeft = css`
  &:last-of-type {
    padding-left: 0;

    @media ${deviceUp.m} {
      padding-left: 0;
    }

    @media ${deviceDown.l} {
      padding-right: 0;
    }
  }
`;

/**
 * This mixin is meant to be used if you don't want any border-spacing to the RIGHT side
 * of the container on desktop devices.
 * On mobile & tablet devices the last Column will be full width (no spacing on both sides).
 * The first column will have the normal spacing towards the sides of the screen.
 *
 * Comment from David Kox, June 2021:
 * If there are more than 2 Columns for e.g. you want a full width image with two columns
 * underneath this mixin would cause a double-width gutter spacing for the two columns under the image.
 * This could be solved by splitting the paddings with "2nd" & "last" operators in the Col.
 * For now we decided that it would be too much overhead to implement it as no use case is in sight.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const noGuttersRightMixin = (reverseOnDesktop?: boolean) => css`
  padding-right: 0;

  @media ${deviceUp.m} {
    padding-right: 0;
  }

  @media ${deviceDown.l} {
    padding-left: 0;
  }

  & > ${Row} {
    margin-right: 0;

    @media ${deviceUp.m} {
      margin-right: 0;
      margin-left: -${spaces.l / 2}px;
    }

    @media ${deviceDown.l} {
      margin-left: 0;
    }

    & > ${Col} {
      padding-left: ${spaces.m}px;
      padding-right: ${spaces.m}px;

      @media ${deviceUp.m} {
        padding-left: ${spaces.l}px;
        padding-right: ${spaces.l}px;
      }

      @media ${deviceUp.l} {
        padding-left: ${spaces.l / 2}px;
        padding-right: ${spaces.l / 2}px;
      }

      ${reverseOnDesktop ? reverseDesktopRight : rowDesktopRight}
    }
  }
`;

const reverseDesktopRight = css`
  &:first-of-type {
    padding-right: 0;

    @media ${deviceUp.m} {
      padding-right: 0;
    }

    @media ${deviceDown.l} {
      padding-left: 0;
    }
  }
`;

const rowDesktopRight = css`
  &:last-of-type {
    padding-right: 0;

    @media ${deviceUp.m} {
      padding-right: 0;
    }

    @media ${deviceDown.l} {
      padding-left: 0;
    }
  }
`;

export const fullWidthMixin = css`
  max-width: unset;

  @media ${deviceUp.l} {
    max-width: unset;
  }
`;

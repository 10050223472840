import styled from "styled-components";
import { spacing } from "../../../theme";
import { deviceUp } from "../Grid";

export const TimeInput = styled.input`
  height: 48px;
  border-radius: 6px;
  border: solid 1px ${({ theme }) => theme.color.monochrome400};
  padding: ${spacing("s")};
  background-color: transparent;
  color: ${({ theme }) => theme.color.monochrome600};
  display: inline-block;
  width: 120px;

  &:focus,
  &:focus-visible,
  .js-focus-visible &.focus-visible {
    outline: solid 1px ${({ theme }) => theme.color.state500};
    border: solid 1px ${({ theme }) => theme.color.state500};
  }

  @media ${deviceUp.m} {
    height: 48px;
    padding: ${spacing("m")};
  }

  /* Wrapper around the hour, minute, second, and am/pm fields as well as 
  the up and down buttons and the 'X' button */

  &[type="time"]::-webkit-datetime-edit-fields-wrapper {
    //display: flex;
  }

  /* The space between the fields - between hour and minute, the minute and 
  second, second and am/pm */

  &[type="time"]::-webkit-datetime-edit-text {
    padding: 0 4px;
  }

  /* The naming convention for the hour, minute, second, and am/pm field is
  \`-webkit-datetime-edit-{field}-field\` */

  /* Hour */

  &[type="time"]::-webkit-datetime-edit-hour-field {
    //
  }

  /* Minute */

  &[type="time"]::-webkit-datetime-edit-minute-field {
    //
  }

  /* AM/PM */

  &[type="time"]::-webkit-datetime-edit-ampm-field {
    //
  }

  /* Clock icon */
  &[type="time"]::-webkit-calendar-picker-indicator {
    filter: ${({ theme }) =>
      theme.name === "ws10dark" ? "invert(1)" : "none"};
  }
`;

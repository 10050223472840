import * as React from 'react';
import { SVGProps } from 'react';

const SvgVCarDongle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.5,16.508v2.828c0,1.195-0.969,2.164-2.164,2.164H8.664c-1.195,0-2.164-0.969-2.164-2.164v-2.828"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.5,19.5h-7"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.941,16.508H6.117c-1.148,0.02-2.105-0.871-2.172-2.02L3.547,4.691C3.5,3.5,4.484,2.508,5.719,2.508h14.824 l-0.43,11.973C20.051,15.629,19.094,16.527,17.941,16.508z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgVCarDongle;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgMail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.1,6.9L13.3,14c-0.7,0.7-1.9,0.8-2.6,0.1c0,0,0,0-0.1-0.1L4.1,7.2 M5.5,6.5c-1.1,0-2,0.9-2,2V16 c0,1.1,0.9,2,2,2h13c1.1,0,2-0.9,2-2V6.5H5.5z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgMail;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgMapGps = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.4,18.3V7.9l5.9-1.4V17L3.4,18.3z M20.6,17l-5.9,1.4L9.3,17V6.5l2.3,0.6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.9,7.2l2.7-0.7V17"
        fill="none"
        strokeLinecap="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={14.7}
        cy={8.9}
        r={1.5}
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.9,8.9c0,2.2-3.2,5-3.2,5s-3.2-2.8-3.2-5c0-1.8,1.4-3.2,3.2-3.2l0,0"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.7,5.7L14.7,5.7c1.8,0,3.2,1.5,3.2,3.2c0,0,0,0,0,0l0,0"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.7,18.3v-2.7"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgMapGps;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgHelpCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.485,8.786c0,0,0.341-1.826,2.515-1.816s2.909,2.678,1.762,3.844c-0.512,0.524-0.951,0.955-1.262,1.257 c-0.32,0.316-0.5,0.748-0.5,1.198v0.966"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12}
        cy={12}
        r={9.5}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={12}
        cy={17}
        r={0.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgHelpCircle;

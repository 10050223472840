/**
 * MSAL Config
 */
export const msalConfig = {
  auth: {
    clientId: "56e8bc6f-52b2-4f5f-9801-66286be5298e",
    authority:
      "https://login.microsoftonline.com/82360567-02b6-4a1f-a4b2-910a811b8131/",
        redirectUri: "https://dashboard.vodafone.dev",
        postLogoutRedirectUri: "https://dashboard.vodafone.dev",
  },
};
/**
 * https://dashboard.vodafone.dev
 */
/**
 * Google Maps API
 */
export const mapsConfig = {
  apiKey: "AIzaSyDg-yzVKLtgRzPem1uiJDBuDC-L812nbWE",
  apiKeyProd: "AIzaSyBstVT5hmaEguYaRnVKlG0CBiY8YSsy6Xo",
};

/**
 * Scopes for requests
 */
export const loginRequest = {
  scopes: ["user.read", "openid", "profile"],
};

export const dataRequest = {
  scopes: ["api://d90572b1-c1fa-44fe-b713-148c7b17fb3e/cosmos.read"],
};

/**
 * Supported Projects
 */

//to add a new card in the PorjectOverview just  go to
// src/config/config.tsx and add the project id in supportedProjects
export const supportedProjects = [
  "hydrosense",
  "alwa",
  "sanitizer-lab",
  "papertowel-poc",
  "ion",
  "gigabee",
  "modbus",
];

import * as React from 'react';
import { SVGProps } from 'react';

const SvgDocumentXls = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.355,2.543c-0.512,0-1.004,0.184-1.391,0.512l-2.703,2.32C5.789,5.777,5.52,6.371,5.52,6.992v12.465 c0,1.098,0.902,2,1.742,2h9.219c1.359,0,2-0.902,2-2V2.543H10.355z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.567,6.543H9.5V2.721"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M11.434,11.465v3.039h1.52 M7.859,11.465l1.898,3.039 M7.797,14.504l1.898-3.039 M14.449,14.328 c0,0,0.363,0.297,1.027,0.258c0.336-0.02,0.801-0.234,0.719-0.84c-0.078-0.609-1.25-0.934-1.25-0.934s-0.492-0.137-0.5-0.613 c-0.027-0.414,0.289-0.773,0.699-0.801h0.051c0.332-0.031,0.668,0.062,0.93,0.273"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgDocumentXls;

import * as React from "react";
import { SVGProps } from "react";

const SvgBolt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        fill="none"
        vectorEffect="non-scaling-stroke"
        d="M18,2.5h-5.293c-0.23,0-0.438,0.144-0.526,0.365L7.544,12.7c-0.073,0.183-0.054,0.392,0.052,0.556 S7.879,13.52,8.07,13.52h2.534c0.084,0,0.164,0.039,0.218,0.106c0.054,0.067,0.077,0.155,0.062,0.241l-1.201,7.287 c-0.022,0.135,0.048,0.268,0.169,0.322s0.262,0.014,0.34-0.095l7.216-10.066c0.129-0.18,0.15-0.42,0.054-0.62 c-0.096-0.201-0.294-0.328-0.509-0.328h-2.505c-0.1,0-0.192-0.055-0.243-0.144c-0.051-0.089-0.054-0.199-0.007-0.291L18,2.5z"
      />
    </g>
  </svg>
);

export default SvgBolt;

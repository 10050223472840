import * as React from 'react';
import { SVGProps } from 'react';

const SvgVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M17,16.5c0,1.105-0.895,2-2,2H4.547c-1.105,0-2-0.895-2-2v-9c0-1.105,0.895-2,2-2H17v5l3.434-3.305 c0.176-0.18,0.445-0.234,0.68-0.137S21.5,7.383,21.5,7.637v8.727c0,0.254-0.152,0.48-0.387,0.578 c-0.234,0.094-0.504,0.043-0.68-0.137L17,13.5V16.5z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M5,8.539h2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgVideo;

import React from "react";
import * as S from "./Subline.styled";

export type SublineProps = {
  text: string;
};

export const Subline: React.FC<SublineProps> = ({ text, ...props }) => {
  return <S.Subline {...props}>{text}</S.Subline>;
};

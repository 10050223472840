import * as React from 'react';
import { SVGProps } from 'react';

const SvgConfidential = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.8,9.4V7.6c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8l0,0v1.8 M15.8,9.4 H9.2c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.5-0.3,0.7v4.1c0,0.3,0.1,0.5,0.3,0.7s0.5,0.3,0.7,0.3h5.6c0.6,0,1-0.5,1-1 C15.8,14.5,15.8,9.4,15.8,9.4z M21.5,4.9c0,12.1-7.8,15.9-9.3,16.6c-0.2,0.1-0.3,0.1-0.5,0C10.2,20.8,2.5,17,2.5,4.9 c0-1.8,3.4,1.2,9.1-2.3c0.3-0.2,0.6-0.2,0.9,0c2.2,1.4,4,1.9,5.5,1.9h3.5V4.9z"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgConfidential;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgLoan = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.516,2.488c1.109,0,2.012,0.898,2.016,2.012v14.438 c-0.02,0.898,0.445,1.734,1.219,2.188c0.773,0.457,1.73,0.457,2.504,0c0.773-0.453,1.238-1.289,1.219-2.188V18.61 c0-0.066,0.059-0.125,0.125-0.125h5.93v-16L4.516,2.488z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.516,2.488C3.402,2.488,2.504,3.387,2.5,4.5v1.047h4.031 M14.969,7.504h-6 M14.969,9.504h-6 M14.969,11.504 h-6 M17.528,18.483H21.5v0.001v0.483c0,1.379-1.121,2.5-2.5,2.5H9.002"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgLoan;

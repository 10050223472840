import * as React from 'react';
import { SVGProps } from 'react';

const SvgAdmin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.677,21c0-4.862,3.279-8.804,7.323-8.804s7.323,3.941,7.323,8.804"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.1,7.7c0,2.3-1.9,4.1-4.1,4.1S7.9,10,7.9,7.7S9.7,3.6,12,3.6h4.1V7.7z"
        fill="none"
      />
    </g>
  </svg>
);

export default SvgAdmin;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgHoney = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M17.6,6.9H6.4c-0.9,0-1.6-0.7-1.6-1.7l0,0c0-1,0.7-1.7,1.6-1.7h11.1c0.9,0,1.6,0.7,1.6,1.7l0,0 C19.2,6.2,18.5,6.9,17.6,6.9z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M6,6.9L4.2,9.6l1.4,0.7c0.8,0.4,1.7,0.4,2.5,0l0.2-0.1c0.8-0.4,1.7-0.4,2.5,0l0.2,0.1c0.8,0.4,1.7,0.4,2.5,0 l0.2-0.1c0.8-0.4,1.7-0.4,2.5,0l0,0c0.9,0.5,2,0.4,2.8-0.2l0.7-0.5L18,6.9"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M18.6,10.4c0.8,3.4,0.4,7.1-1.4,10.1l0,0H6.8l0,0c-1.8-3.1-2.3-6.9-1.4-10.3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polygon
        vectorEffect="non-scaling-stroke"
        points="13.3,13.1 10.7,13.1 9.4,15.4 10.7,17.7 13.3,17.7 14.6,15.4 "
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgHoney;

import styled, { createGlobalStyle } from "styled-components";

export const ContentContainer = styled.div`
  width: 100%;
  overflow-x: hidden;

  &.js-focus-visible *:focus:not(.focus-visible) {
    outline: none;
  }

  &.js-focus-visible .focus-visible {
    outline: solid 1px ${({ theme }) => theme.color.secondary100} !important;
    filter: grayscale(0%) !important;
    z-index: 3;
  }

  position: relative;
`;

export const BaseCSS = createGlobalStyle`
  html {
    display: flex;
    justify-content: center;
  }

  body {
    background-color: #f2f2f2;
    width: 100%;
  }

  a {
    color: #0d0d0d;
    text-decoration: underline;
    background-color: transparent;

    :hover {
      color: #e60000;
      text-decoration: underline;
    }
  }
`;

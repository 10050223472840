import * as React from 'react';
import { SVGProps } from 'react';

const SvgSwitchCamera = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.4,5.6L4,9.2l3.6-0.6 M4,9.2c2.8-5.1,7.6-6.5,11.7-4.5c2.5,1.2,4.2,3.7,4.5,6.4 M20.6,18.4L20,14.8l-3.6,0.6  M12,10.5c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5S11.2,10.5,12,10.5z M20,14.8c-2.9,5.2-7.7,6.5-11.7,4.5 c-2.2-1.1-3.8-3.1-4.3-5.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgSwitchCamera;

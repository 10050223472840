import React, { FC } from "react";
import * as S from "./Section.styles";
import { SectionProps } from "./Section.types";

export const Section: FC<SectionProps> = ({
  children,
  paddingBottom,
  paddingTop,
  maxGridWidth,
  backgroundColor,
  ...props
}) => {
  return (
    <S.Section
      paddingBottom={paddingBottom}
      paddingTop={paddingTop}
      backgroundColor={backgroundColor}
      {...props}
    >
      <S.SectionGrid maxGridWidth={maxGridWidth}>{children}</S.SectionGrid>
    </S.Section>
  );
};

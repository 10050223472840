import * as React from 'react';
import { SVGProps } from 'react';

const SvgTrees = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.4,19.1L20.4,19.1c-0.6-0.3-1.5-0.6-2.4-0.9V14c0.2,0.1,0.3,0.1,0.5,0.1c1,0,1.9-0.8,1.9-1.9 c0-0.4-0.1-0.8-0.3-1.1c0.4-0.4,0.6-0.9,0.6-1.4c0-0.8-0.4-1.4-1.1-1.8c0-1.2-1-2.2-2.3-2.2c-0.6,0-1.2,0.2-1.6,0.6 c-0.1,0.1-0.5,0.4-0.8,1.2 M9.3,7.5C8.2,5.8,7.6,5.7,6.8,5.7C6.1,5.7,5.6,6,5.2,6.4C4.8,6.8,4.5,7.4,4.5,8C3.8,8.3,3.4,9,3.4,9.7 c0,0.6,0.2,1.1,0.6,1.4c-0.2,0.3-0.3,0.7-0.3,1.1c0,1,0.8,1.9,1.9,1.9c0.2,0,0.3,0,0.5-0.1v4.2c-0.9,0.2-1.8,0.6-2.4,0.9 M14.2,13 c0.4,0.7,1.4,1.1,1.9,1.1c0.2,0,0.3,0,0.5-0.1v3.8c-1.2-0.2-2.5-0.3-3.9-0.4v-4.3c0.2,0.1,0.3,0.1,0.5,0.1c1,0,1.9-0.8,1.9-1.9 c0-0.4-0.1-0.8-0.3-1.1c0.4-0.4,0.6-0.9,0.6-1.4c0-0.8-0.4-1.4-1.1-1.8c-0.1-1.2-1.1-2.2-2.3-2.2s-2.2,1-2.3,2.2 c-0.6,0.3-1.1,1-1.1,1.8c0,0.6,0.2,1.1,0.6,1.4C9.1,10.6,9,10.9,9,11.4c0,1,0.8,1.9,1.9,1.9c0.2,0,0.3,0,0.5-0.1v4.3 c-1.4,0-2.7,0.2-3.9,0.4V14c0.2,0.1,0.3,0.1,0.5,0.1c0.5,0,1.3-0.3,1.8-1.2 M6,18.2C6.6,18,6.7,18,7.5,17.8 M11.3,17.4 c-0.3,0,1.2,0,1.4,0 M16.6,17.9c0.4,0,1.2,0.3,1.4,0.3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgTrees;

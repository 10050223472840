import * as React from 'react';
import { SVGProps } from 'react';

const SvgQrCode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    vectorEffect="non-scaling-stroke"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.5,6.5v-3h-3"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.5,3.5h-3v3 M3.5,17.5v3h3 M17.5,20.5h3v-3 M7.25,6.5h2.5c0.414,0,0.75,0.336,0.75,0.75v2.5 c0,0.414-0.336,0.75-0.75,0.75h-2.5c-0.414,0-0.75-0.336-0.75-0.75v-2.5C6.5,6.836,6.836,6.5,7.25,6.5z M7.25,13.5h2.5 c0.414,0,0.75,0.336,0.75,0.75v2.5c0,0.414-0.336,0.75-0.75,0.75h-2.5c-0.414,0-0.75-0.336-0.75-0.75v-2.5 C6.5,13.836,6.836,13.5,7.25,13.5z M14.25,13.5h0.5c0.414,0,0.75,0.336,0.75,0.75v0.5c0,0.414-0.336,0.75-0.75,0.75h-0.5 c-0.414,0-0.75-0.336-0.75-0.75v-0.5C13.5,13.836,13.836,13.5,14.25,13.5z M14.25,6.5h2.5c0.414,0,0.75,0.336,0.75,0.75v2.5 c0,0.414-0.336,0.75-0.75,0.75h-2.5c-0.414,0-0.75-0.336-0.75-0.75v-2.5C13.5,6.836,13.836,6.5,14.25,6.5z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgQrCode;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgWeight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.593,3.582c0,1.411-1.153,2.579-2.579,2.579c-1.411,0-2.579-1.153-2.579-2.579s1.153-2.579,2.579-2.579 C13.44,1.018,14.593,2.171,14.593,3.582z M22.301,20.348c0.197,0.592,0.106,1.244-0.273,1.745 c-0.364,0.501-0.941,0.804-1.563,0.804H3.592c-1.062,0-1.927-0.865-1.927-1.942c0-0.212,0.03-0.425,0.106-0.622L5.944,8.361 c0.273-0.774,1.001-1.305,1.821-1.305"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.765,7.057l9.939,0.015c0.956,2.807,3.49,9.954,4.598,13.277"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgWeight;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgNotificationMute = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.896,19.031v0.364c-0.028,1.162,0.891,2.127,2.053,2.156s2.127-0.891,2.156-2.053 c0.001-0.034,0-0.466,0-0.466"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.625,4.744V4.125C13.625,3.228,12.897,2.5,12,2.5l0,0c-0.87,0.003-1.575,0.708-1.578,1.579v0.697"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.693,19.031c-0.694,0.008-1.277-0.519-1.34-1.21v-0.105c0-0.542,0.289-1.043,0.759-1.315 c0.944-0.545,1.577-1.688,1.577-2.855l-0.021-2.65c0-3.487,2.826-6.314,6.313-6.315c0.035,0,0.07,0,0.105,0.001 c3.486-0.06,6.362,2.718,6.422,6.204c0.001,0.037,0.001,0.073,0.001,0.11l-0.022,2.65c0,1.167,0.635,2.31,1.579,2.855 c0.469,0.272,0.757,0.773,0.757,1.315v0.105c-0.063,0.691-0.646,1.218-1.34,1.21H4.693z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        vectorEffect="non-scaling-stroke"
        x1={3.526}
        y1={20.505}
        x2={20.421}
        y2={3.61}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgNotificationMute;

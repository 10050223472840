import * as React from 'react';
import { SVGProps } from 'react';

const SvgBeeHive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.5,18.8H8.3c-0.7,0-1.4-0.6-1.4-1.4l0,0c0-0.7,0.6-1.4,1.4-1.4h8.1c0.7,0,1.4,0.6,1.4,1.4l0,0 C17.8,18.2,17.3,18.8,16.5,18.8z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.5,21.5h-4.1c-0.7,0-1.4-0.6-1.4-1.4l0,0c0-0.7,0.6-1.4,1.4-1.4h4.1c0.7,0,1.4,0.6,1.4,1.4l0,0 C15.8,20.9,15.2,21.5,14.5,21.5z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.3,5.2h8.1c0.7,0,1.4,0.6,1.4,1.4l0,0c0,0.7-0.6,1.4-1.4,1.4H8.3C7.6,7.9,7,7.3,7,6.6l0,0 C7,5.8,7.6,5.2,8.3,5.2z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.3,2.5h4.1c0.7,0,1.4,0.6,1.4,1.4l0,0c0,0.7-0.6,1.4-1.4,1.4h-4.1C9.6,5.3,9,4.7,9,3.9l0,0 C9,3.1,9.6,2.5,10.3,2.5z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polygon
        vectorEffect="non-scaling-stroke"
        points="13.8,9.6 11,9.6 9.7,12 11,14.4 13.8,14.4 15.1,12  "
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.5,13.4h3.4c0.7,0,1.4,0.6,1.4,1.4l0,0c0,0.7-0.6,1.4-1.4,1.4H7c-0.7,0-1.4-0.6-1.4-1.4l0,0 c0-0.7,0.6-1.4,1.4-1.4h3.4"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.4,10.6H7c-0.7,0-1.4-0.6-1.4-1.4l0,0c0-0.7,0.6-1.4,1.4-1.4h10.9c0.7,0,1.4,0.6,1.4,1.4l0,0 c0,0.7-0.6,1.4-1.4,1.4h-3.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.3,10.6h4.9c0.7,0,1.4,0.6,1.4,1.4l0,0c0,0.7-0.6,1.4-1.4,1.4h-4.9"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.5,13.4H5.6c-0.7,0-1.4-0.6-1.4-1.4l0,0c0-0.7,0.6-1.4,1.4-1.4h4.9"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgBeeHive;

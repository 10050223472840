import * as React from 'react';
import { SVGProps } from 'react';

const SvgWaterlevel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.3,5.4c-1.7,2.2-4.2,5.8-4.2,9c0,3.4,2.5,6.2,5.9,6.2s5.9-2.7,5.9-6.1C17.9,9.8,12,3.4,12,3.4 S10.7,4.9,10.3,5.4z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.3,9.9c0,0,1.7,2.1,4.1,1c1.8-0.7,2.1-1.7,5.4-0.4"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.8,13.7c0,1.3,0.5,2.6,1.5,3.4"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgWaterlevel;

import styled from "styled-components";
import { spacing } from "../../../theme/utils/spacing";
import { Col, deviceUp } from "../Grid";
import { fontSize } from "../../../theme/utils/font-size";

export const ProjectCardWrapper = styled(Col)`
  margin-bottom: ${spacing("l")};
`;

export const ProjectCard = styled.div`
  background-color: #ffffff;
  color: #0d0d0d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  padding: ${spacing("m", "l", "xxl", "l")};
  height: 100%;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  picture {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 6px;
    overflow: hidden;
    background-color: #bebebe;
  }

  img {
    display: block;
    object-fit: cover;
    height: auto;
    width: 100%;
  }
}
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
`;

export const Subline = styled.p`
  display: block;
  padding: 0;
  font-weight: normal;
  ${fontSize("xxs", "h5")};
  hyphens: manual;
  margin: ${spacing(0, 0, "l", 0)};

  @media ${deviceUp.m} {
    ${fontSize("m", "h5")};
  }

  @media ${deviceUp.l} {
    ${fontSize("l", "h5")};
  }
`;

export const ProjectCardHeadline = styled.h3`
  display: block;
  padding: 0;
  font-weight: bold;
  ${fontSize("xxs", "h4")};
  hyphens: manual;
  margin-bottom: ${spacing("l")};

  @media ${deviceUp.m} {
    ${fontSize("m", "h4")};
  }

  @media ${deviceUp.l} {
    ${fontSize("l", "h4")};
  }

  margin: ${spacing("l", 0, 0, 0)};
  height: 35px;

  @media ${deviceUp.m} {
    height: 75px;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgEnvironment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M20.429,7.09c-0.256,5.727-5.065,10.272-10.797,10.21c-0.93-0.009-1.854-0.14-2.75-0.389 c-1.436-5.73,2.046-11.539,7.776-12.975c0.914-0.229,1.853-0.336,2.795-0.319h2.974L20.429,7.09z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.466,20.288c3.044-4.793,6.949-8.979,11.519-12.349"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgEnvironment;

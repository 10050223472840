import * as React from 'react';
import { SVGProps } from 'react';

const SvgChat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.444,13.705c-0.035-0.68-0.242-1.341-0.601-1.92c-0.24-0.386-0.535-0.736-0.875-1.039l-0.015-0.012 c-0.672-0.537-1.428-0.96-2.237-1.25h-0.019l-0.274-0.085c-1.56-0.401-3.206-0.3-4.705,0.289c-0.69,0.271-1.329,0.659-1.889,1.145 c-0.527,0.45-0.957,1.003-1.262,1.625c-0.3,0.616-0.44,1.297-0.409,1.981c0.038,0.679,0.246,1.338,0.606,1.915 c0.364,0.585,0.846,1.088,1.416,1.475c0.609,0.417,1.286,0.723,2,0.906c0.745,0.197,1.517,0.277,2.286,0.236 c0.228,0.148,0.465,0.282,0.709,0.401c0.361,0.175,0.738,0.317,1.125,0.423c0.449,0.125,0.91,0.198,1.375,0.219 c0.089,0,0.177,0.006,0.266,0.006c0.446,0,0.891-0.046,1.327-0.139c0.194-0.038,0.344-0.193,0.376-0.388 c0.032-0.195-0.061-0.39-0.234-0.487c-0.461-0.269-1.086-0.774-1.291-1.201c0.285-0.189,0.553-0.401,0.803-0.635 c0.322-0.302,0.606-0.643,0.842-1.016c0.231-0.364,0.408-0.759,0.525-1.174C21.409,14.566,21.461,14.135,21.444,13.705z  M17.895,9.561c0.109-1.151-0.56-2.18-0.656-2.319c-0.456-0.727-1.06-1.349-1.771-1.829c-0.765-0.522-1.616-0.906-2.512-1.138 C10.987,3.772,8.91,3.9,7.019,4.641C6.151,4.98,5.348,5.465,4.644,6.075c-0.661,0.564-1.2,1.257-1.581,2.037 c-0.37,0.764-0.542,1.609-0.5,2.458c0.026,0.537,0.139,1.067,0.336,1.568c0.198,0.495,0.468,0.959,0.801,1.375 c0.341,0.427,0.736,0.809,1.175,1.135c0.453,0.301,0.934,0.557,1.436,0.765c-0.656,1.125-1.781,1.812-1.794,1.819 c-0.175,0.106-0.262,0.312-0.216,0.511c0.046,0.199,0.215,0.346,0.418,0.364c0.227,0.017,0.455,0.028,0.682,0.028 c0.445,0,0.889-0.036,1.328-0.109c0.475-0.078,0.942-0.199,1.395-0.361c0.023-0.007,0.531-0.192,1.9-0.938"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgChat;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgReturnProduct = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M9.5,7.469H6.734c-2.34,0-4.234,1.895-4.234,4.23v2.27c0,0.828,0.672,1.5,1.5,1.5h1.586 M19.414,15.469 c1.164-0.043,2.086-1,2.086-2.164V5.469h-10c-1.105,0-2,0.895-2,2v8h7.086 M8.414,15.469H9.5"
        fill="none"
        strokeLinecap="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={7}
        cy={15.969}
        r={1.5}
        fill="none"
        strokeLinejoin="round"
      />
      <circle
        vectorEffect="non-scaling-stroke"
        cx={18}
        cy={15.969}
        r={1.5}
        fill="none"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.488,8.961l1.492,1.496 M13,12.973h3.5c1.105,0,2.004-0.898,2.004-2.004c0-1.109-0.898-2.004-2.004-2.004 h-4.012 M13.984,7.465l-1.496,1.496"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgReturnProduct;

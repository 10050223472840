import * as React from 'react';
import { SVGProps } from 'react';

const SvgUnlockedRewardsSystem = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.844,12.473h16.312c0.191,0,0.344,0.152,0.344,0.344v6.656c0,1.105-0.895,2-2,2h-13c-1.105,0-2-0.895-2-2 v-6.656C3.5,12.625,3.652,12.473,3.844,12.473z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.57,21.473v-9"
        fill="none"
        strokeWidth={1}
        strokeMiterlimit={10}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.57,12.473v9"
        fill="none"
        strokeWidth={1}
        strokeMiterlimit={10}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M21.316,6.924c0.141,0.535-0.18,1.082-0.711,1.223L4.156,12.44c-0.535,0.141-1.082-0.18-1.223-0.715 l-0.25-0.969c-0.137-0.531,0.18-1.078,0.715-1.219l17.414-4.551L21.316,6.924z"
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M6.793,8.658C5.457,9.006,4.09,8.205,3.742,6.869C3.461,5.803,4.102,4.713,5.164,4.432l1.5-0.391 C8,3.693,9.367,4.49,9.715,5.826l0.504,1.937"
        fill="none"
        strokeWidth={1}
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.117,6.998l-0.504-1.937c-0.348-1.336,0.449-2.699,1.785-3.051l1.496-0.391 c1.07-0.277,2.164,0.363,2.441,1.43c0.348,1.336-0.449,2.703-1.785,3.051l-0.008,0.004"
        fill="none"
        strokeWidth={1}
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M13.121,7.018l0.758,2.902"
        fill="none"
        strokeWidth={1}
        strokeMiterlimit={10}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.977,10.678l-0.758-2.902"
        fill="none"
        strokeWidth={1}
        strokeMiterlimit={10}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.215,7.756L9.848,6.35c-0.211-0.801,0.27-1.621,1.074-1.832c0.801-0.207,1.621,0.273,1.828,1.074 l0.367,1.406"
        fill="none"
        strokeWidth={1}
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
    </g>
  </svg>
);

export default SvgUnlockedRewardsSystem;

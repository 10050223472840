import * as React from 'react';
import { SVGProps } from 'react';

const SvgLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M12,6.5c1.9,0,3.5,1.6,3.5,3.5s-1.6,3.5-3.5,3.5S8.5,11.9,8.5,10S10.1,6.5,12,6.5z M19.5,10 c0,5-7.5,11.5-7.5,11.5S4.5,15,4.5,10c0-4.1,3.4-7.5,7.5-7.5 M12,2.5L12,2.5c2,0,3.9,0.8,5.3,2.2c1.4,1.4,2.2,3.3,2.2,5.3l0,0"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgLocation;

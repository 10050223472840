import * as React from 'react';
import { SVGProps } from 'react';

const SvgNews = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.5,2.5h11v17c0,1.105-0.895,2-2,2h-9c-1.105,0-2-0.895-2-2v-15 C5.5,3.395,6.395,2.5,7.5,2.5z"
        fill="none"
        strokeLinecap="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M7.5,5.5h9 M14,8.5h2.5 M14,12.5h2.5 M13.5,18.5h3 M7.5,18.5h4 M7.5,15.535h9 M7.5,8.5h4v4h-4V8.5z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgNews;

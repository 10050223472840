import {
  DataProps,
  DeviceProps,
  getMaxValues,
  Measurement,
} from "../../../data";
import { DateRangeProps, zoomLevelProps } from "./Chart.types";
import _ from "lodash";
import moment from "moment/moment";

//    return dataMapping(dateFilter(wall, dateRange), measurement, zoomLevel);

export function dataMapping(
  initialData: DeviceProps[],
  measurement: string,
  zoomLevel: zoomLevelProps,
  dateRange: DateRangeProps,
  limitLow: number,
  limitHigh: number,
  format?: string
) {
  // Filter data to only reflect set dateRange & sort by timestamp
  const chartData = dateFilter(initialData, dateRange).sort((a, b) =>
    a.timestamp < b.timestamp ? -1 : 1
  );

  /**
   * Prepare data for chart with: data, labels & options
   */
  switch (zoomLevel) {
    case "hourly":
      const groupedByHour = _.groupBy(chartData, (data) => {
        return moment(data.timestamp, "YYYY-MM-DDTHH:mm").startOf("hour");
      });
      return consolidateData(groupedByHour, measurement, limitLow, limitHigh);
    case "daily":
      const groupedByDay = _.groupBy(chartData, (data) => {
        return moment(data.timestamp.split("T")[0], "YYYY-MM-DD").startOf(
          "day"
        );
      });
      return consolidateData(groupedByDay, measurement, limitLow, limitHigh);
    case "weekly":
      const groupedByWeek = _.groupBy(chartData, (data) => {
        return moment(data.timestamp.split("T")[0], "YYYY-MM-DD").startOf(
          "isoWeek"
        );
      });
      return consolidateData(groupedByWeek, measurement, limitLow, limitHigh);
    default:
      return allData(chartData, measurement, format);
  }
}

function allData(data: DeviceProps[], measurement: string, format?: string) {
  const labels = data.map((o) =>
    moment(o.timestamp).format(format ?? "YYYY-MM-DD HH:mm:ss")
  );

  const values = data.map((entry) => {
    if (["fill_percent"].includes(measurement)) {
      return (entry.data[measurement as Measurement] * 100).toFixed(2);
    }

    return entry.data[measurement as Measurement];
  });

  const datasets = [
    {
      data: values,
      label: "Value",
      backgroundColor: "#e60000",
      borderColor: "#e60000",
      fill: false,
    },
  ];
  return { labels, datasets };
}

function consolidateData(
  data: any,
  measurement: string,
  limitLow: number,
  limitHigh: number
) {
  const labels = Object.keys(data);
  const highestData: number[] = [];
  const lowestData: number[] = [];

  const formattedLabels = labels.map((key) => {
    const maxValues = getMaxValues(
      data[key],
      measurement as Measurement,
      limitLow,
      limitHigh
    );

    // Add value to array
    if (["fill_percent"].includes(measurement)) {
      highestData.push(Number((maxValues.highestVal.value * 100).toFixed(2)));
      lowestData.push(Number((maxValues.lowestVal.value * 100).toFixed(2)));
    } else {
      highestData.push(maxValues.highestVal.value);
      lowestData.push(maxValues.lowestVal.value);
    }

    return moment(key).format("YYYY-MM-DD");
  });

  const datasets = [
    {
      label: "Highest",
      data: highestData,
      backgroundColor: "#e60000",
      borderColor: "#e60000",
      fill: false,
    },
    {
      label: "Lowest",
      data: lowestData,
      backgroundColor: "#3333333",
      borderColor: "#333333",
      fill: false,
    },
  ];
  return { labels: formattedLabels, datasets };
}

function dateFilter(data: DeviceProps[], dateRange: DateRangeProps) {
  return data.filter((data) => {
    const timestamp = new Date(data.timestamp);
    return timestamp >= dateRange.start && timestamp <= dateRange.end;
  });
}

import * as React from 'react';
import { SVGProps } from 'react';

const SvgSanitizer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    enableBackground="new 0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <g stroke="#0D0D0D" fill="#0D0D0D">
      <path
        vectorEffect="non-scaling-stroke"
        d="M14.2,15.7c0.4,0.1,0.5,0.3,0.5,0.7S14.4,17,14,17 M14.2,15.7c0,0-3-0.6-3.8-0.8c-0.4-0.1-0.9-0.2-1.3-0.3 c-0.8-0.3-1.7-0.2-2.5,0.2S4.7,16.4,4.4,18 M14.1,17L14.1,17 M8.9,17.5c0,0,0.6-0.5,1-0.5c0.6,0,4,0,4.1,0 M14.7,16.1l4.3-1.9 c0.4-0.2,0.8,0,1,0.3c0.2,0.4,0.1,0.9-0.2,1.1L12,19.5c-0.4,0.2-0.9,0.2-1.3,0.2H6.1 M18.3,14.5c0-0.2,0-0.4-0.1-0.5 c-0.2-0.3-0.7-0.5-1-0.3l-4,1.8 M15.7,14.4c0-0.2,0-0.5-0.1-0.6c-0.2-0.3-0.7-0.4-1-0.3l-3.4,1.6 M3.8,17.4L7,20.6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.1,9.9l1.3-1.3 M12.3,11.6V9.8 M16.4,9.9l-1.3-1.3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M16.7,3.4v1.8c0,0.9-0.8,1.7-1.7,1.7H9c-1,0-1.7-0.7-1.7-1.7V3.4 M10.9,6.9v0.4h2.3V6.9"
        fill="none"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgSanitizer;
